import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { customLocaleText, lightColor } from "./utils";
import { useTranslation } from "react-i18next";

type CustomTableProps = {
  columns: GridColDef[];
  rows: any[];
  dense?: boolean;
  onRowClick?: (row: any) => void;
  checkbox?: {
    visible: boolean;
    selectedRows: any[];
    setSelectedRows: (rows: any[]) => void;
  };
};

const CustomTable: React.FC<CustomTableProps> = ({
  columns,
  rows,
  dense,
  onRowClick,
  checkbox,
}) => {
  const { t } = useTranslation();

  return (
    <DataGrid
      sx={{
        ".MuiDataGrid-columnHeaderTitle": {
          fontWeight: "bold",
          fontSize: "1.1rem",
          color: "white",
        },
        ".MuiDataGrid-columnHeader": {
          backgroundColor: lightColor,
          border: "none",
        },
        ".MuiDataGrid-row": {
          cursor: onRowClick ? "pointer" : undefined,
        },
      }}
      getRowId={(row) => rows.indexOf(row)}
      density={dense ? "compact" : "standard"}
      rows={rows}
      columns={columns}
      checkboxSelection={checkbox && checkbox.visible ? true : false}
      rowSelection={checkbox && checkbox.visible ? true : false}
      onRowSelectionModelChange={
        checkbox && checkbox.visible
          ? (newSelection) => checkbox.setSelectedRows(newSelection)
          : undefined
      }
      onRowClick={onRowClick ? (params) => onRowClick(params.row) : undefined}
      localeText={customLocaleText(t)}
      isCellEditable={() => false}
    />
  );
};

export default CustomTable;
