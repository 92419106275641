import React from "react";
import { darkColor, lightColor } from "./utils";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

type CustomTitleProps = {
  title: string;
  subtitle?: string;
  goBack?: () => void;
};

const CustomTitle: React.FC<CustomTitleProps> = ({
  title,
  subtitle,
  goBack,
}) => {
  return (
    <Grid container alignItems="center" style={{ padding: "8px" }}>
      <Grid item container xs={12} alignItems="center">
        <Grid item xs={0.5}>
          {goBack && (
            <IconButton
              size="small"
              onClick={goBack}
              style={{ backgroundColor: lightColor }}
            >
              <ArrowBack
                fontSize="small"
                htmlColor={darkColor}
                style={{ color: "white" }}
              />
            </IconButton>
          )}
        </Grid>
        <Grid xs={11.5} item container flexDirection="column">
          <Grid item>
            <Typography variant="h5" style={{ color: darkColor }}>
              {title}
            </Typography>
          </Grid>
          {subtitle && (
            <Grid item>
              <Typography variant="subtitle2" style={{ color: darkColor }}>
                {subtitle}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ borderColor: lightColor, borderWidth: "1.2px" }} />
      </Grid>
    </Grid>
  );
};

export default CustomTitle;
