import { Card, CardActions, CardContent, CardHeader } from "@mui/material";
import React from "react";

type CustomCardProps = {
  content: React.ReactNode;
  header?: {
    visible: boolean;
    avatar?: React.ReactNode;
    action?: React.ReactNode;
    title?: string;
    subtitle?: string;
  };
  actions?: React.ReactNode[];
};

const CustomCard: React.FC<CustomCardProps> = ({
  content,
  header,
  actions,
}) => {
  return (
    <Card variant="outlined">
      {header && header.visible && (
        <CardHeader
          avatar={header.avatar && header.avatar}
          action={header.action && header.action}
          title={header.title && header.title}
          subheader={header.subtitle && header.subtitle}
        />
      )}
      <CardContent>{content}</CardContent>
      {actions && (
        <CardActions style={{ justifyContent: "flex-end" }}>
          {actions.map((a: React.ReactNode) => a)}
        </CardActions>
      )}
    </Card>
  );
};

export default CustomCard;
