import { apiKey, isDev } from "../pages/costant";

export const POST = "POST";
export const GET = "GET";
export const PUT = "PUT";
export const DELETE = "DELETE";

export const GrafanaFetch = (
  method: string,
  url: string,
  param?: string,
  auth?: string
): Promise<any> => {
  return fetch(url, {
    method: method,
    credentials: "include",
    body: param,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: auth ? auth : `Basic ${btoa("admin:Z3rynthT3st")}`, //apikey creata da grafana (scade il 5/12/2023): eyJrIjoidlVxQ29EWVZEZjZ3QWFoN2ppS2hiWFFxdW5iVUdkTHIiLCJuIjoidGVzdF9ndWkiLCJpZCI6MX0=
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
};

export const ZDMFetch = (
  method: string,
  url: string,
  param?: string,
  auth?: string
): Promise<any> => {
  return fetch(url, {
    method: method,
    body: param,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-KEY": apiKey,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
};

export const binaryFetch = (method: string, url: string, param?: string) => {
  const requestOptions: RequestInit = {
    method: method,
    credentials: "include" as RequestCredentials,
    headers: {
      "X-API-KEY": apiKey,
    },
    body: param,
  };
  const mockRequestOptions: RequestInit = {
    method: method,
    body: param,
    headers: {
      Accept: "application/octet-stream",
      "Content-Type": "application/json",
      "X-API-KEY": apiKey,
    },
  };
  return fetch(url, isDev ? mockRequestOptions : requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.arrayBuffer();
      }
      throw new Error(response.statusText);
    })
    .catch((error) => console.log(error));
};
