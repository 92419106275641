import React, { useState, useEffect } from "react";
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import CustomTitle from "../../custom/CustomTitle";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import CustomButton from "../../custom/CustomButton";
import CustomText from "../../custom/CustomText";
import CustomSelect from "../../custom/CustomSelect";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUsers, createUser } from "../../endpoints/api";
import useStore from "../../State";
import { enqueueSnackbar } from "notistack";

const Name: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const deviceName = useStore((state: any) => state.deviceName);
  const setDeviceName = useStore((state: any) => state.setDeviceName);
  const userId = useStore((state: any) => state.userId);
  const setUserId = useStore((state: any) => state.setUserId);

  const [name, setName] = useState<string>(deviceName ? deviceName : "");
  const [user, setUser] = useState<string>(userId ? userId : "");

  const [users, setUsers] = useState<{ label: string; value: string }[]>([]);
  useEffect(() => {
    fetchUsers();
  }, []); // Initial fetch on component mount

  const fetchUsers = () => {
    getUsers().then((users: any) => {
      let tmpUsers: { label: string; value: string }[] = [];
      if (users && users.length > 0) {
        users.forEach((u: any) => {
          if (u && u.email && u.id) {
            tmpUsers.push({ label: u.email, value: `${u.id}%%${u.email}` });
          }
        });
        setUsers([...tmpUsers]);
      }
    });
  };

  const [openForm, setOpenForm] = useState(false);
  const [newUserName, setNewUserName] = useState("");

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setNewUserName("");
  };

  const handleCreateUser = () => {
    if (newUserName) {
      createUser(newUserName).then((newUser: any) => {
        if (newUser && newUser.id) {
          setUsers([...users, { label: newUser.email, value: `${newUser.id}%%${newUser.email}` }]);
          enqueueSnackbar(t("userCreated"), { variant: "success" });
          handleCloseForm();

          // Refresh the user list after creating a new user
          fetchUsers();
        } else {
          enqueueSnackbar(t("userCreationError"), { variant: "error" });
        }
      });
    } else {
      enqueueSnackbar(t("invalidUserName"), { variant: "error" });
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <CustomTitle goBack={() => navigate("/home")} title={`${t("name")}`} />
      </Grid>
      <Grid item xs={7}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomText type="h6" label={t("nameDescription")} />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  label={t("name")}
                  value={name}
                  handleChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomSelect
                  label={t("user")}
                  value={user}
                  handleChange={(e) => setUser(e.target.value)}
                  options={users}
                />
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} md>
                  <CustomButton
                    confirm
                    fullWidth
                    label={t("cancel")}
                    onClick={() => setName("")}
                    type="outlined"
                  />
                </Grid>
                <Grid item xs={12} md>
                  <CustomButton
                    fullWidth
                    label={t("ok")}
                    onClick={() => {
                      if (name) {
                        setDeviceName(name);
                        setUserId(user);
                        navigate("/network");
                      } else {
                        enqueueSnackbar(t("invalidFields"), {
                          variant: "error",
                        });
                      }
                    }}
                    type="contained"
                  />
                </Grid>
                <Grid item xs={12} md>
                  <CustomButton
                    fullWidth
                    label={t("addNewUser")}
                    onClick={handleOpenForm}
                    type="contained"
                  />
                </Grid>
              </Grid>
            </Grid>
          }
        />
      </Grid>

      {/* Form Dialog */}
      <Dialog open={openForm} onClose={handleCloseForm}>
        <DialogTitle>{t("addNewUser")}</DialogTitle>
        <DialogContent>
          <CustomText type="h6" label={t("enterUserName")} />
          <CustomInput
            label={t("name")}
            value={newUserName}
            handleChange={(e) => setNewUserName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <CustomButton label={t("cancel")} onClick={handleCloseForm} type="outlined" />
          <CustomButton label={t("create")} onClick={handleCreateUser} type="contained" />
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Name;
