import React, { useEffect, useState } from "react";
import logo from "../../images/mapro.png";
import {
  AppBar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
} from "@mui/material";
import { Info, Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useStore from "../../State";
import CustomIconButton from "../../custom/CustomIconButton";
import { darkColor } from "../../custom/utils";
import CustomButton from "../../custom/CustomButton";
import { useTranslation } from "react-i18next";

const Header: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const setLoggedIn = useStore((state: any) => state.setLoggedIn);
  const loggedIn = useStore((state: any) => state.loggedIn);
  const writingFw = useStore((state: any) => state.writingFw);
  useEffect(() => {
    if (!loggedIn) navigate("/login");
  }, [loggedIn]);

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const handleOpenConfirm = () => setConfirmOpen(true);
  const handleCloseConfirm = () => setConfirmOpen(false);

  return (
    <>
      <AppBar position="static" style={{ backgroundColor: "white" }}>
        <Grid
          container
          alignItems="center"
          style={{ padding: "4px 32px" }}
          spacing={2}
        >
          <Grid item>
            <img
              src={logo}
              alt="logo"
              style={{ width: "50px" }}
              onClick={() => navigate("/home")}
            />
          </Grid>
          <Grid item xs />
          <Grid item>
            <CustomIconButton icon={<Info />} onClick={handleOpenConfirm} />
            <Dialog open={confirmOpen} onClose={handleCloseConfirm}>
              <DialogContent>
                <DialogContentText>{t("driverInfo")}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <CustomButton
                  type="outlined"
                  label={t("close")}
                  onClick={handleCloseConfirm}
                />
                <CustomButton
                  type="contained"
                  label={t("link")}
                  onClick={() => {
                    window.open(
                      "https://docs.zerynth.com/latest/zerynth_guide/machines_connection_and_management/zerynth_toolbox_for_commissioning/#install-drivers-windows-only",
                      "_blank"
                    );
                    handleCloseConfirm();
                  }}
                />
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item>
            <CustomIconButton
              icon={<Logout />}
              disabled={writingFw}
              onClick={() => {
                setLoggedIn(false);
                navigate("/login");
              }}
            />
          </Grid>
        </Grid>
      </AppBar>
      <Divider style={{ borderColor: darkColor, borderWidth: "2px" }} />
    </>
  );
};

export default Header;
