import { create } from "zustand";
import { devtools } from "zustand/middleware";

const useStore = create(
  devtools((set) => ({
    loggedIn: localStorage.getItem("loginmapro") === "true" ? true : false,
    setLoggedIn: (loggedIn: boolean) => {
      localStorage.setItem("loginmapro", loggedIn ? "true" : "false");
      set(() => ({ loggedIn: loggedIn }));
    },
    user: localStorage.getItem("usermapro")
      ? JSON.parse(localStorage.getItem("usermapro")!)
      : null,
    setUser: (user: { name: string; password: string; admin: boolean }) => {
      localStorage.setItem("usermapro", JSON.stringify(user));
      set(() => ({ user: user }));
    },
    deviceName: "",
    setDeviceName: (deviceName: string) =>
      set(() => ({ deviceName: deviceName })),
    userId: "",
    setUserId: (userId: string) => set(() => ({ userId: userId })),
    network: {
      IFCS: [],
    },
    setNetwork: (network: any) => set(() => ({ network: network })),
    plc: {
      PLC_CLIENTS: [],
    },
    setPlc: (plc: any) => set(() => ({ plc: plc })),
    variables: [],
    setVariables: (variables: any[]) => set(() => ({ variables: variables })),
    writingFw: false,
    setWritingFw: (writingFw: boolean) => set(() => ({ writingFw: writingFw })),
  }))
);

export default useStore;
