import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { SnackbarProvider } from "notistack";
import "./i18n";
import { Grid, ThemeProvider, createTheme } from "@mui/material";
import errorImage from "./images/error.svg";
import useStore from "./State";
import Header from "./pages/header/Header";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import { darkColor, lightColor } from "./custom/utils";
import Network from "./pages/network/Network";
import NetworkWifi from "./pages/network/NetworkWifi";
import NetworkEth from "./pages/network/NetworkEth";
import NetworkBoth from "./pages/network/NetworkBoth";
import Params from "./pages/params/Params";
import Connecting from "./pages/connecting/Connecting";
import End from "./pages/connecting/End";
import NewVariable from "./pages/params/NewVariable";
import Name from "./pages/name/Name";
import NetworkGsm from "./pages/network/NetworkGsm";
import Plc from "./pages/plc/Plc";

const errorFallback = (
  t: TFunction<"translation", undefined, "translation">
) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          marginTop: "10%",
        }}
      >
        <img src={errorImage} alt="error" width="30%" height="30%" />
      </div>
      <h1 style={{ textAlign: "center" }}>{t("somethingWentWrong")}</h1>
      <h3 style={{ textAlign: "center" }}>{t("refreshPage")}</h3>
    </div>
  );
};

const theme = createTheme({
  palette: {
    background: {
      default: lightColor,
      paper: "white",
    },
    primary: { main: darkColor },
    mode: "light",
    text: {
      primary: darkColor,
      secondary: lightColor,
    },
    secondary: { main: lightColor },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: darkColor,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        labelContainer: {
          color: darkColor,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: darkColor,
        },
      },
    },
  },
});

declare global {
  interface Window {
    _env_: any;
    api: any;
  }
}

function App() {
  const { t } = useTranslation();
  const loggedIn = useStore((state: any) => state.loggedIn);

  return (
    <ErrorBoundary FallbackComponent={() => errorFallback(t)}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <BrowserRouter>
            <Grid container flexDirection="column" spacing={4}>
              {loggedIn && (
                <Grid item>
                  <Header />
                </Grid>
              )}
              <Grid item>
                <Routes>
                  <Route path="/network/wifi" element={<NetworkWifi />} />
                  <Route path="/network/both" element={<NetworkBoth />} />
                  <Route path="/network/eth" element={<NetworkEth />} />
                  <Route path="/network/gsm" element={<NetworkGsm />} />
                  <Route path="/network" element={<Network />} />
                  <Route path="/plc" element={<Plc />} />
                  <Route path="/name" element={<Name />} />
                  <Route path="/params/new" element={<NewVariable />} />
                  <Route path="/params" element={<Params />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/connecting" element={<Connecting />} />
                  <Route path="/end" element={<End />} />
                  <Route path="/*" element={<Login />} />
                </Routes>
              </Grid>
            </Grid>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
