import React, { useState } from "react";
import { darkColor } from "./utils";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "./CustomButton";

type CustomIconButtonProps = {
  icon: any; //TODO: Fix any
  onClick: (() => void) | ((e: any) => void);
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  confirm?: boolean;
  confirmQuestion?: string;
  type?: "contained" | "outlined";
};

const CustomIconButton: React.FC<CustomIconButtonProps> = ({
  icon,
  size,
  onClick,
  disabled,
  confirm,
  confirmQuestion,
  type,
}) => {
  const { t } = useTranslation();

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const handleOpenConfirm = () => setConfirmOpen(true);
  const handleCloseConfirm = () => setConfirmOpen(false);

  return (
    <>
      <IconButton
        size={size ? size : "large"}
        onClick={confirm ? handleOpenConfirm : onClick}
        disabled={disabled ? disabled : undefined}
        style={{
          backgroundColor:
            disabled || type === "outlined" ? undefined : darkColor,
          color: disabled || type === "outlined" ? undefined : "white",
        }}
      >
        {icon}
      </IconButton>
      <Dialog open={confirmOpen} onClose={handleCloseConfirm}>
        <DialogContent>
          <DialogContentText>
            {confirmQuestion ? confirmQuestion : t("areYouSure")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton
            type="outlined"
            label={t("no")}
            onClick={handleCloseConfirm}
          />
          <CustomButton
            type="contained"
            label={t("yes")}
            onClick={(e: any) => {
              onClick(e);
              handleCloseConfirm();
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomIconButton;
