import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse, Grid, Switch } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { isValidIPv4, isValidSSID, isValidWPA2 } from "../costant";
import { useNavigate } from "react-router-dom";
import CustomTitle from "../../custom/CustomTitle";
import CustomCard from "../../custom/CustomCard";
import CustomButton from "../../custom/CustomButton";
import { enqueueSnackbar } from "notistack";
import CustomText from "../../custom/CustomText";
import CustomInput from "../../custom/CustomInput";
import CustomIconButton from "../../custom/CustomIconButton";
import useStore from "../../State";

const NetworkBoth: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const network = useStore((state: any) => state.network);
  const setNetwork = useStore((state: any) => state.setNetwork);

  const [configuration, setConfiguration] = useState<any>(
    network && network.IFCS.length === 2
      ? network
      : {
          IFCS: [
            {
              ifc_name: "wifi",
              ifc_params: {
                ssid: "Default",
                pwd: "Mapro",
                dhcp: true,
             
              },
            },
            {
              ifc_name: "eth",
              ifc_params: {
                dhcp: true,
             
              },
            },
          ],
        }
  );

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const checkFields = () => {
    if (
      !(
        configuration.IFCS[0].ifc_params.ssid &&
        configuration.IFCS[0].ifc_params.pwd &&
        isValidSSID(configuration.IFCS[0].ifc_params.ssid) &&
        isValidWPA2(configuration.IFCS[0].ifc_params.pwd)
      )
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    console.log("NET.JSON", configuration);
  }, [configuration]);

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <CustomTitle
          goBack={() => navigate("/network")}
          title={`${t("networkConfiguration")}`}
          subtitle={`${t("wifiSettingsDescription")}`}
        />
      </Grid>
      <Grid item xs={12} md={7} key="wifi">
        <CustomCard
          content={
            <Grid container spacing={2} direction="column">
              <Grid item>
                <CustomText type="h6" label={t("wifi")} />
              </Grid>
              <Grid item>
                <CustomText type="h6" label={t("mainNetwork")} />
              </Grid>
              <Grid item>
                <CustomInput
                  label={t("networkSsid")}
                  value={configuration.IFCS[0].ifc_params.ssid}
                  handleChange={(e) =>
                    setConfiguration({
                      ...configuration,
                      IFCS: [
                        {
                          ...configuration.IFCS[0],
                          ifc_params: {
                            ...configuration.IFCS[0].ifc_params,
                            ssid: e.target.value,
                          },
                        },
                        configuration.IFCS[1],
                      ],
                    })
                  }
                  validator={{
                    error:
                      !!configuration.IFCS[0].ifc_params.ssid &&
                      !isValidSSID(configuration.IFCS[0].ifc_params.ssid),
                    helperText: `${t("invalidSsid")}`,
                  }}
                />
              </Grid>
              <Grid item>
                <CustomInput
                  label={t("networkPassword")}
                  type={showPassword ? "text" : "password"}
                  value={configuration.IFCS[0].ifc_params.pwd}
                  handleChange={(e) =>
                    setConfiguration({
                      ...configuration,
                      IFCS: [
                        {
                          ...configuration.IFCS[0],
                          ifc_params: {
                            ...configuration.IFCS[0].ifc_params,
                            pwd: e.target.value,
                          },
                        },
                        configuration.IFCS[1],
                      ],
                    })
                  }
                  validator={{
                    error:
                      !!configuration.IFCS[0].ifc_params.pwd &&
                      !isValidWPA2(configuration.IFCS[0].ifc_params.pwd),
                    helperText: `${t("invalidPassword")}`,
                  }}
                  endAdornment={
                    <CustomIconButton
                      type="outlined"
                      icon={
                        showPassword ? (
                          <VisibilityOff style={{ fontSize: "18px" }} />
                        ) : (
                          <Visibility style={{ fontSize: "18px" }} />
                        )
                      }
                      onClick={handleShowPassword}
                    />
                  }
                />
              </Grid>
              <Grid item>
                <CustomText type="h6" label={t("ipv4")} />
              </Grid>
              <Grid item>
                <Switch
                  checked={!configuration.IFCS[0].ifc_params.dhcp}
                  onChange={() =>
                    setConfiguration({
                      ...configuration,
                      IFCS: [
                        {
                          ...configuration.IFCS[0],
                          ifc_params: {
                            ...configuration.IFCS[0].ifc_params,
                            dhcp: !configuration.IFCS[0].ifc_params.dhcp,
                          },
                        },
                        configuration.IFCS[1],
                      ],
                    })
                  }
                />
                {!configuration.IFCS[0].ifc_params.dhcp
                  ? " " + t("manual")
                  : " " + t("automaticDhcp")}
              </Grid>
              <Grid item>
                <Collapse
                  in={!configuration.IFCS[0].ifc_params.dhcp}
                  timeout="auto"
                  unmountOnExit
                >
                  <Grid container spacing={2} direction="column">
                    <Grid item>
                      <CustomInput
                        label={t("ipAddress")}
                        value={configuration.IFCS[0].ifc_params.ip}
                        handleChange={(e) =>
                          setConfiguration({
                            ...configuration,
                            IFCS: [
                              {
                                ...configuration.IFCS[0],
                                ifc_params: {
                                  ...configuration.IFCS[0].ifc_params,
                                  ip: e.target.value,
                                },
                              },
                              configuration.IFCS[1],
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!configuration.IFCS[0].ifc_params.ip &&
                            !isValidIPv4(configuration.IFCS[0].ifc_params.ip),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <CustomInput
                        label={t("subnetMask")}
                        value={configuration.IFCS[0].ifc_params.mask}
                        handleChange={(e) =>
                          setConfiguration({
                            ...configuration,
                            IFCS: [
                              {
                                ...configuration.IFCS[0],
                                ifc_params: {
                                  ...configuration.IFCS[0].ifc_params,
                                  mask: e.target.value,
                                },
                              },
                              configuration.IFCS[1],
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!configuration.IFCS[0].ifc_params.mask &&
                            !isValidIPv4(configuration.IFCS[0].ifc_params.mask),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <CustomInput
                        label={t("gateway")}
                        value={configuration.IFCS[0].ifc_params.gateway}
                        handleChange={(e) =>
                          setConfiguration({
                            ...configuration,
                            IFCS: [
                              {
                                ...configuration.IFCS[0],
                                ifc_params: {
                                  ...configuration.IFCS[0].ifc_params,
                                  gateway: e.target.value,
                                },
                              },
                              configuration.IFCS[1],
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!configuration.IFCS[0].ifc_params.gateway &&
                            !isValidIPv4(
                              configuration.IFCS[0].ifc_params.gateway
                            ),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <CustomInput
                        label={t("dns")}
                        value={configuration.IFCS[0].ifc_params.dns}
                        handleChange={(e) =>
                          setConfiguration({
                            ...configuration,
                            IFCS: [
                              {
                                ...configuration.IFCS[0],
                                ifc_params: {
                                  ...configuration.IFCS[0].ifc_params,
                                  dns: e.target.value,
                                },
                              },
                              configuration.IFCS[1],
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!configuration.IFCS[0].ifc_params.dns &&
                            !isValidIPv4(configuration.IFCS[0].ifc_params.dns),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12} md={7} key="eth">
        <CustomCard
          content={
            <Grid container spacing={2} direction="column">
              <Grid item>
                <CustomText type="h6" label={t("ethernet")} />
              </Grid>
              <Grid item>
                <CustomText type="h6" label={t("ipv4")} />
              </Grid>
              <Grid item>
                <Switch
                  checked={!configuration.IFCS[1].ifc_params.dhcp}
                  onChange={() =>
                    setConfiguration({
                      ...configuration,
                      IFCS: [
                        configuration.IFCS[0],
                        {
                          ...configuration.IFCS[1],
                          ifc_params: {
                            ...configuration.IFCS[1].ifc_params,
                            dhcp: !configuration.IFCS[1].ifc_params.dhcp,
                          },
                        },
                      ],
                    })
                  }
                />
                {!configuration.IFCS[1].ifc_params.dhcp
                  ? " " + t("manual")
                  : " " + t("automaticDhcp")}
              </Grid>
              <Grid item>
                <Collapse
                  in={!configuration.IFCS[1].ifc_params.dhcp}
                  timeout="auto"
                  unmountOnExit
                >
                  <Grid container spacing={2} direction="column">
                    <Grid item>
                      <CustomInput
                        label={t("ipAddress")}
                        value={configuration.IFCS[1].ifc_params.ip}
                        handleChange={(e) =>
                          setConfiguration({
                            ...configuration,
                            IFCS: [
                              configuration.IFCS[0],
                              {
                                ...configuration.IFCS[1],
                                ifc_params: {
                                  ...configuration.IFCS[1].ifc_params,
                                  ip: e.target.value,
                                },
                              },
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!configuration.IFCS[1].ifc_params.ip &&
                            !isValidIPv4(configuration.IFCS[1].ifc_params.ip),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <CustomInput
                        label={t("subnetMask")}
                        value={configuration.IFCS[1].ifc_params.mask}
                        handleChange={(e) =>
                          setConfiguration({
                            ...configuration,
                            IFCS: [
                              configuration.IFCS[0],
                              {
                                ...configuration.IFCS[1],
                                ifc_params: {
                                  ...configuration.IFCS[1].ifc_params,
                                  mask: e.target.value,
                                },
                              },
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!configuration.IFCS[1].ifc_params.mask &&
                            !isValidIPv4(configuration.IFCS[1].ifc_params.mask),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <CustomInput
                        label={t("gateway")}
                        value={configuration.IFCS[1].ifc_params.gateway}
                        handleChange={(e) =>
                          setConfiguration({
                            ...configuration,
                            IFCS: [
                              configuration.IFCS[0],
                              {
                                ...configuration.IFCS[1],
                                ifc_params: {
                                  ...configuration.IFCS[1].ifc_params,
                                  gateway: e.target.value,
                                },
                              },
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!configuration.IFCS[1].ifc_params.gateway &&
                            !isValidIPv4(
                              configuration.IFCS[1].ifc_params.gateway
                            ),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <CustomInput
                        label={t("dns")}
                        value={configuration.IFCS[1].ifc_params.dns}
                        handleChange={(e) =>
                          setConfiguration({
                            ...configuration,
                            IFCS: [
                              configuration.IFCS[0],
                              {
                                ...configuration.IFCS[1],
                                ifc_params: {
                                  ...configuration.IFCS[1].ifc_params,
                                  dns: e.target.value,
                                },
                              },
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!configuration.IFCS[1].ifc_params.dns &&
                            !isValidIPv4(configuration.IFCS[1].ifc_params.dns),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item container spacing={2} xs={12} md={7}>
        <Grid item xs={12} md>
          <CustomButton
            confirm
            fullWidth
            label={t("cancel")}
            onClick={() =>
              setConfiguration({
                IFCS: [
                  {
                    ifc_name: "wifi",
                    ifc_params: {
                      ssid: "Default",
                      pwd: "Mapro",
                      dhcp: true,
                      ip: "",
                      mask: "",
                      gateway: "",
                      dns: "",
                    },
                  },
                  {
                    ifc_name: "eth",
                    ifc_params: {
                      dhcp: true,
                      ip: "",
                      mask: "",
                      gateway: "",
                      dns: "",
                    },
                  },
                ],
              })
            }
            type="outlined"
          />
        </Grid>
        <Grid item xs={12} md>
          <CustomButton
            fullWidth
            label={t("ok")}
            onClick={() => {
              if (checkFields()) {
                setNetwork({ ...configuration });
                navigate("/plc");
              } else {
                enqueueSnackbar(t("invalidFields"), {
                  variant: "error",
                });
              }
            }}
            type="contained"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NetworkBoth;
