import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Add,
  SettingsInputHdmiOutlined,
  SimCardOutlined,
  Wifi,
} from "@mui/icons-material";
import CustomTitle from "../../custom/CustomTitle";
import { useNavigate } from "react-router-dom";
import CustomCard from "../../custom/CustomCard";
import CustomText from "../../custom/CustomText";

const Network: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const switchIcon = (type: string) => {
    switch (type) {
      case "gsm":
        return (
          <Grid
            container
            flexDirection="column"
            alignItems="center"
            spacing={2}
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/network/gsm")}
          >
            <Grid item>
              <SimCardOutlined style={{ fontSize: "56px" }} />
            </Grid>
            <Grid item>
              <CustomText label={t("machineNetworkGsm")} type="h5" />
            </Grid>
          </Grid>
        );
      case "wifi":
        return (
          <Grid
            container
            flexDirection="column"
            alignItems="center"
            spacing={2}
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/network/wifi")}
          >
            <Grid item>
              <Wifi style={{ fontSize: "56px" }} />
            </Grid>
            <Grid item>
              <CustomText label={t("machineNetworkWifi")} type="h5" />
            </Grid>
          </Grid>
        );
      case "eth":
        return (
          <Grid
            container
            flexDirection="column"
            alignItems="center"
            spacing={2}
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/network/eth")}
          >
            <Grid item>
              <SettingsInputHdmiOutlined style={{ fontSize: "56px" }} />
            </Grid>
            <Grid item>
              <CustomText label={t("ethernet")} type="h5" />
            </Grid>
          </Grid>
        );
      case "both":
        return (
          <Grid
            container
            flexDirection="column"
            alignItems="center"
            spacing={2}
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/network/both")}
          >
            <Grid
              item
              container
              xs={12}
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Wifi style={{ fontSize: "56px" }} />
              </Grid>
              <Grid item>
                <Add style={{ fontSize: "26px" }} />
              </Grid>
              <Grid item>
                <SettingsInputHdmiOutlined style={{ fontSize: "56px" }} />
              </Grid>
            </Grid>
            <Grid item>
              <CustomText
                label={`${t("wifi")} +  ${t("ethernet")}`}
                type="h5"
              />
            </Grid>
          </Grid>
        );
      default:
        return;
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <CustomTitle
          goBack={() => navigate("/name")}
          title={`${t("network")}`}
          subtitle={`${t("selectConnectivityType")}`}
        />
      </Grid>
      <Grid
        item
        container
        spacing={2}
        style={{ paddingLeft: "64px", paddingRight: "64px" }}
      >
        {["wifi", "both", "eth", "gsm"].map((i: string, index: number) => {
          return (
            <Grid key={index} item xs={12} md>
              <CustomCard content={switchIcon(i)} />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Network;
