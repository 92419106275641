//VALIDATION
export function isValidIPv4(str: string) {
  return /^(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?)$/g.test(
    str
  );
}

export function isValidSSID(str: string) {
  return /^[^!#;+\]/"\t][^+\]/"\t]{0,30}[^ +\]/"\t]$|^[^ !#;+\]/"\t]$[ \t]+$/g.test(
    str
  );
}

export function isValidWPA2(str: string) {
  return /^[\u0020-\u007e]{8,63}$/g.test(str);
}

//ZFS
function maproParamJsonAdapter(paramsFromConfig: any) {
  let CRC = 0;
  const newDict: any = { VARS: [] };
  const VARS = paramsFromConfig.VARS;
  console.log("VARS", VARS)

  const maps = {maps_value: "INT16" ,size: 1}
  const fixed_list = [["INT16",1,"h"], ["UINT16",1,"H"], ["INT32",2,"i"] ,  ["UINT32",2,"I"], ["INT64",4,'l'] ,  ["UINT64",4,"L"] ,  ["Float32",2,"f"]]
  const var_list = [["string",1,"str"], ["boolean",1,"b"]]



  VARS.map((varItem: any[]) => {
    if (varItem.length >= 8) {
      let size = 1; // default size
      let types = "H"
      const fixedItem = fixed_list.find(item => item[0] === varItem[3]);
      if (fixedItem) {
        size = Number(fixedItem[1]);
        types = fixedItem[2].toString();
      } else {
        // Check if varItem[4] is in varList
        const varItemInfo = var_list.find(item => item[0] === varItem[3]);
        if (varItemInfo) {
          size = Number(varItem[2]);
          types = varItemInfo[2].toString();
        }
      }
      newDict.VARS.push([
        Number(varItem[1]),
        // Number(varItem[2]),
        size,
        // varItem[3],
        types,
        varItem[5],
        varItem[6],
        varItem[7],
      ]);
    }
  });
  CRC = 200;
  console.log("New dict",newDict)
  return newDict;
}

export const prepareParamsJson = (variables: any[]) => {
  const tmp: any[] = [];
  variables.forEach((variable: any) => {
    tmp.push([
      variable.name,
      variable.address,
      variable.length,
      variable.type,
      variable.unit === "Null" ? "" : variable.unit,
      variable.rw,
      variable.threshold === "Null" ? "" : variable.threshold,
      variable.logic === "Null" ? "" : variable.logic,
    ]);
  });
  const firstResult = {
    MODBUS_IP: "192.168.1.134",
    VARS: [...tmp],
  };
  return JSON.stringify(maproParamJsonAdapter(firstResult));
};

export const prepareNetJson = (network: any, plc: any) => {
  let NETJSON = {
    IFCS: network.IFCS,
    PLC_CLIENTS: [
      plc.PLC_CLIENTS.length > 0
        ? {
            ...plc.PLC_CLIENTS[0],
            plc_proto: "modbus_tcp",
            plc_params: {
              address: `${plc.PLC_CLIENTS[0].plc_params.ip}`,
              port: Number(`${plc.PLC_CLIENTS[0].plc_params.port}`),
            },
          }
        : {},
    ],
  }
  
  console.log("network", NETJSON)
  return JSON.stringify(NETJSON);
};

export const prepareSensorsJson = () => {
  return JSON.stringify({});
};

export const prepareConfigJson = () => {
  return JSON.stringify({
    params: {
      wtd: 60000,
      acq_freq: 1000,
      fast_freq: 0,
      pub_freq: 1000,
      agent_timeout: 1500,
      store_period: 60,
      info_period: 3600,
      exc_period: 3600,
      dn_chunk_size: 32768,
      dn_wtd: 120000,
    },
    exps: [
      {
        type: "connect",
        sw1: 0,
      },
    ],
  });
};

//ZDM

/*
CREDENZIALI DI TEST (account: s.falchi@zerynth.com)

export const workspaceId = "wks-6k89mtbypzx6";
export const fleetId = "flt-6k89mtd4lwnp";
export const firmwareId = "generic-4zb";
export const apiKey =
  "Rm63mP2mAl.X19W8f2Ub7TfjjrkwwLCjJlwoNk-UEZrbef7NCXpsvtoeUQESk39shFnN1dHcz55";

*/

//CREDENZIALI MAPRO
export const workspaceId = "wks-8f5l6qb9j76d";
export const fleetId = "flt-8f5l6qbeizva";
export const firmwareId = "fmw-8hkgqhrxs0t2";
export const apiKey =
  "pgWqLZm3MR.wbQtsNdYB7NCR26JnXDnwWN2PZJYWY$rIuk8fx6e65lJy6vTFmBQaBEZMl5-aUu0";
export const dashboardUid = "bOSzrxd4kohj32";

//MOCK
export const isDev =
  process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test";

//GRAFANA
// export const adjustGrafanaDashboard = (rawDashboard: any, variables: any[]) => {


//   return rawDashboard;
// };

export const adjustGrafanaDashboard = (rawDashboard: any, variables: any[]): any => {
  // console.log("variables",variables)
  // rawDashboard = rawDashboard.dashboard
    const rowModel = {
      collapsed: false,
      datasource: null,
      gridPos: {
        h: 1,
        w: 24,
        x: 0,
        y: 17,
      },
      id: 61,
      panels: [],
      title: "Row prova",
      type: "row",
    };
  
    const panels = rawDashboard.panels;
  
    try {
      delete rawDashboard['id'];
    } catch (error) {
      // Do nothing if the key is not present
    }
  
    try {
      delete rawDashboard['uid'];
    } catch (error) {
      // Do nothing if the key is not present
    }
  
    let index = 0;
    let panelModel: any = null;
    let CRC = 0;
    let last_position: any = null;
  
    // TODO remember to remove the UID from the dashboards
    // SEZIONE ELETTROVALVOLE
    // 1) Identifico il pannello modello
    // console.log("panels",panels)

    for (const panel of panels || []) {
      try {
          // Check if panel is defined and has a title property
          // console.log("panel",panel)
          // console.log("panel.title",panel.title)
          if (panel && panel.title && panel.title.includes("valv")) {
              panelModel = panel;
              break;
          }
      } catch (error) {
          // Log the error or handle it as needed
          console.error("Error in panel iteration:", error);
      }
  }
  
    // 2) Creo i pannelli per le valvole
    console.log("PRIMA")
    // console.log("panelModel",panelModel)
    console.log(variables[0])
    let pos_index = 0;
    if (panelModel) {
      console.log("DOPO")
      const baseId = panelModel.id;
      index = 0;
      const model: any = JSON.parse(JSON.stringify(panelModel));
      const posizioneRiga = model.gridPos.y - 1;
  
      for (let i = 0; i < variables.length; i++) {
        const varName = variables[i]["name"];
        console.log("varName", varName)
        if (varName.includes("valv")) {
          index += 1;
          
          // console.log("index", index)
  
          if (index > 1) {
            pos_index = index
            const newPanel: any = JSON.parse(JSON.stringify(model));
            newPanel.id = 400 + baseId + index;
            newPanel.title = varName;
            newPanel.gridPos.y = (model.gridPos.y * index) + (model.gridPos.h) * index;
            last_position = (model.gridPos.y * index) + (model.gridPos.h) * index;
            const originalSqlQuery = model.targets[0].rawSql;
            let modifiedSqlQuery = originalSqlQuery.replace("{vars, 1, 0}", `{vars, ${i}, 0}`);
            modifiedSqlQuery = modifiedSqlQuery.replace("\"Ev1\"", `\"Ev${index}\"`);
            newPanel.targets[0].rawSql = modifiedSqlQuery;
  
            panels.push(newPanel);
          }
        }
      }
  
      // Insert the row
      const rowModel = {
        gridPos: { y: posizioneRiga },
        title: "Sezione valvole",
        collapsed: false,
        datasource: null,
        id: 0, // Set the appropriate ID for the row
        panels: [],
        type: "row",
      };
  
      panels.push(rowModel);
      console.log("Inserita riga valvole")
  
      // Update the JSON dashboard
      rawDashboard.panels = panels;
      CRC = 200;
    }
  
    // ########## SEIZIONE CUSTOM VAR ##########
    let panelModel_VAR_TS: any = null;

    for (const panel of panels || []) {
      try {
          // Check if panel is defined and has a title property
          // console.log("panel",panel)
          // console.log("panel.title",panel.title)
          if (panel && panel.title && panel.title.includes("Custom_VAR_TS")) {

              panelModel_VAR_TS = panel;
              break;
          }
      } catch (error) {
          // Log the error or handle it as needed
          console.error("Error in custom panel iteration:", error);
      }
  }
  let panelModel_VAR_STAT: any = null;

    for (const panel of panels || []) {
      try {
          // Check if panel is defined and has a title property
          // console.log("panel",panel)
          // console.log("panel.title",panel.title)
          if (panel && panel.title && panel.title.includes("Custom_VAR_STAT")) {

            panelModel_VAR_STAT = panel;
              break;
          }
      } catch (error) {
          // Log the error or handle it as needed
          console.error("Error in custom panel iteration: STAT", error);
      }
  }
  
  if (panelModel_VAR_STAT && panelModel_VAR_TS) {
    // console.log("Ci sono delle custom var ")
    const baseId = panelModel.id;
    index = 0;
    const model_TS: any = JSON.parse(JSON.stringify(panelModel_VAR_TS));
    const model_STAT: any = JSON.parse(JSON.stringify(panelModel_VAR_STAT));
    if (last_position != null) {
      model_TS.gridPos.y = last_position + 2
      model_STAT.gridPos.y = last_position + 2
    }
    const posizioneRiga = model_TS.gridPos.y - 1;

    for (let i = 0; i < variables.length; i++) {
      const varName = variables[i]["name"];
      console.log("varName_before custom", varName)
      if (varName.includes("custom_") && !varName.includes("valv")) {
        console.log("varName customs", varName)
        index += 1;
        // pos_index +=1
        // console.log("index", index)

        if (index >= 1) {
          // Qui metto tutti e due i pannelli, sia gli stat che i time series
          // SEZIONE TS
          const newPanel: any = JSON.parse(JSON.stringify(model_TS));
          newPanel.id = 600 + baseId + index;
          newPanel.title = varName;
          newPanel.gridPos.y = (model_TS.gridPos.y * index) + (model_TS.gridPos.h) * index;
          newPanel.fieldConfig.defaults.unit = variables[i]["unit"]


          const originalSqlQuery = model_TS.targets[0].rawSql;
          let modifiedSqlQuery = originalSqlQuery.replace("{vars, 1, 0}", `{vars, ${i}, 0}`);
          modifiedSqlQuery = modifiedSqlQuery.replace("\"Ev1\"", `\"${varName}\"`);
          newPanel.targets[0].rawSql = modifiedSqlQuery;

          panels.push(newPanel);
          // #####################
          // SEZIONE STAT
          const newPanel2: any = JSON.parse(JSON.stringify(model_STAT));
          newPanel2.id = 700 + baseId + index+1;
          newPanel2.title = varName;
          newPanel2.gridPos.y = (model_STAT.gridPos.y * index) + (model_STAT.gridPos.h) * index;
          newPanel2.fieldConfig.defaults.unit = variables[i]["unit"]

          const originalSqlQuery2 = model_STAT.targets[0].rawSql;
          let modifiedSqlQuery2 = originalSqlQuery2.replace("{vars, 1, 0}", `{vars, ${i}, 0}`);
          modifiedSqlQuery2 = modifiedSqlQuery2.replace("\"Ev1\"", `\"${varName}\"`);
          newPanel2.targets[0].rawSql = modifiedSqlQuery;

          panels.push(newPanel2);
          // console.log("----------------------------------------")
          // console.log(newPanel2);
          // console.log("----------------------------------------")





        }
      }
    }

    // Insert the row
    const rowModel = {
      gridPos: { y: posizioneRiga },
      title: "Sezione custom",
      collapsed: false,
      datasource: null,
      id: 0, // Set the appropriate ID for the row
      panels: [],
      type: "row",
    };

    // panels.push(rowModel);
    panels.push(rowModel);
    console.log("Inserita riga custom var")
    // console.log("Inserita riga valvole")

    // Update the JSON dashboard
    rawDashboard.panels = panels;
    CRC = 200;
  }
  
    // if (panelModel2) {
    //     // Do something
    // }
  
    return rawDashboard;
  };
