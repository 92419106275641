import {
  Alert,
  CircularProgress,
  Grid,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import CustomTitle from "../../custom/CustomTitle";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomCard from "../../custom/CustomCard";
import CustomText from "../../custom/CustomText";
import { useCallback, useEffect, useState } from "react";
import { ESPLoader } from "../../misc/esp-web-flasher";
import { binaryFetch } from "../../endpoints/http";
import {
  ActiveSim,
  basicZDMUrl,
  claimDevice,
  commit,
  createDashboard,
  createFolder,
  createNewDevice,
  getBasicDashboard,
  getFirmwareVersions,
  getFolders,
  getIdentityFromDCN,
  mklfs,
  setPermission,
  uploadFiles,
} from "../../endpoints/api";
import {
  firmwareId,
  fleetId,
  prepareConfigJson,
  prepareNetJson,
  prepareParamsJson,
  prepareSensorsJson,
  workspaceId,
} from "../costant";
import { Dangerous, UsbOff } from "@mui/icons-material";
import CustomButton from "../../custom/CustomButton";
import useStore from "../../State";

const Connecting: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const deviceName: string = useStore((state: any) => state.deviceName);
  const network = useStore((state: any) => state.network);
  const plc = useStore((state: any) => state.plc);
  const variables = useStore((state: any) => state.variables);
  const userId = useStore((state: any) => state.userId);
  const writingFw = useStore((state: any) => state.writingFw);
  const setWritingFw = useStore((state: any) => state.setWritingFw);

  useEffect(() => {
    if (
      !userId ||
      !deviceName ||
      network.IFCS.length === 0 ||
      plc.PLC_CLIENTS.length === 0
    ) {
      navigate("/home");
    }
  }, []);

  const partitions = {
    bootloader: new ArrayBuffer(0), //os/bootloader.bin
    partitions: new ArrayBuffer(0), //os/partitions.bin
    zerynth: new ArrayBuffer(0), //os/zerynth.bin
    firmware: new ArrayBuffer(0), //firmware/firmware.bin
    otalog: new ArrayBuffer(0), //os/otalog.bin
    fs: new ArrayBuffer(0), //resources/fs.bin
  };

  const [devicePort, setDevicePort] = useState<SerialPort | null>(null);
  const [usbDisconnected, setUsbDisconnected] = useState<boolean>(false);
  const [warning, setWarning] = useState<boolean>(false);
  const [deviceId, setDeviceId] = useState<string>("");
  const [processError, setProcessError] = useState<string>("");
  const [flashPercentage, setFlashPercentage] = useState<number>(0);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [steps, setSteps] = useState<any[]>([
    {
      label: t("checkingUsbConnection"),
      status: "pending",
    },
    {
      label: t("settingFirmwarePreferences"),
      status: "tbd",
    },
    {
      label: t("updatingTheFirmware"),
      status: "tbd",
    },
    {
      label: t("claimingDevice"),
      status: "tbd",
    },
    {
      label: t("creatingDashboard"),
      status: "tbd",
    },
  ]);

  //STEP 1: checkingUsbConnection
  const handleDeviceSelect = () => {
    navigator.serial
      .requestPort({ filters: [{ usbVendorId: 0x10c4, usbProductId: 0xea60 }] })
      .then((port) => {
        console.log("port selected:", port);
        setDevicePort(port);
        setWritingFw(true);
        const tmp = [...steps];
        tmp[0].status = "success";
        tmp[1].status = "pending";
        setActiveStep((prev) => prev + 1);
        setSteps([...tmp]);
        handleFirmwarePreferences(port);
      })
      .catch((e) => {
        console.log("no serial port selected:", e);
      });
  };

  //STEP 2: updatingTheFirmware
  const openPort = async (port: SerialPort, baudRate: number, retries = 3) => {
    if (retries > 0) {
      await port
        .open({ baudRate: baudRate })
        .then(() => {
          console.log("serial port opened successfully");
          navigator.serial.onconnect = () => {
            console.log("connected");
          };

          navigator.serial.ondisconnect = () => {
            setUsbDisconnected(true);
            console.log("disconnected");
            setSteps([
              {
                label: t("checkingUsbConnection"),
                status: "pending",
              },
              {
                label: t("settingFirmwarePreferences"),
                status: "tbd",
              },
              {
                label: t("updatingTheFirmware"),
                status: "tbd",
              },
              {
                label: t("claimingDevice"),
                status: "tbd",
              },
              {
                label: t("creatingDashboard"),
                status: "tbd",
              },
            ]);
            setActiveStep(0);
            setDevicePort(null);
            console.log("disconnected");
          };
          return true;
        })
        .catch(async (e: any) => {
          console.log("error during port opening:", e);
          console.log("trying to close the port and reopen it again");
          await port.close();
          await openPort(port, baudRate, retries - 1);
          return false;
        });
    } else {
      setProcessError("Retries finished: failed to open the port");
      console.log("retries finished. failed to open the port.");
    }
  };

  const getPartitions = async () => {
    try {
      const vers = (await getFirmwareVersions()) as any;
      const version = vers.versions[0].version || "v0.0.0";
      for (const k of Object.keys(partitions)) {
        console.log("Starting fetching firmware", k);
        if (k != "fs") {
          // @ts-ignore
          const fileIndex: number = vers.versions[0].sources.find((s: any) =>
            s.name.includes(k)
          ).src_id;
          console.log("fileIndex", fileIndex);
          // @ts-ignore
          partitions[k] = (await binaryFetch(
            "GET",
            `${basicZDMUrl}/workspaces/${workspaceId}/firmwares/${firmwareId}/versions/${version}/download?source=${fileIndex}`
          )) as ArrayBuffer;
        }
      }

      console.log("before mklfsService");
      const resp = await mklfs({
        files: {
          "zfs/net.json": prepareNetJson(network, plc) as string,
          "zfs/params.json": prepareParamsJson(variables) as string,
          "zfs/sensors.json": prepareSensorsJson() as string,
          "zfs/config.json": prepareConfigJson() as string,
        },
      });
      if (!resp) {
        setProcessError("Failed MKLFS");
        console.log("failed to mklfs");
        return "";
      } else {
        console.log("RESP", resp);
        partitions["fs"] = resp as ArrayBuffer;
        console.log("after mklfsService");
        //zfs
        return createNewDevice({ name: deviceName, fleet_id: fleetId }).then(
          async (r) => {
            console.log("after createNewDevice");
            if (r && r.device && r.device.id) {
              console.log("device created successfully");
              setDeviceId(r.device.id);
              const netFile = {
                file: new File(
                  [
                    new TextEncoder().encode(prepareNetJson(network, plc)),
                  ] as BlobPart[],
                  "net.json"
                ),
              };
              const paramsFile = {
                file: new File(
                  [
                    new TextEncoder().encode(prepareParamsJson(variables)),
                  ] as BlobPart[],
                  "params.json"
                ),
              };
              const sensorsFile = {
                file: new File(
                  [
                    new TextEncoder().encode(prepareSensorsJson()),
                  ] as BlobPart[],
                  "sensors.json"
                ),
              };
              const configFile = {
                file: new File(
                  [new TextEncoder().encode(prepareConfigJson())] as BlobPart[],
                  "config.json"
                ),
              };
              await uploadFiles(r.device.id, [
                netFile,
                paramsFile,
                sensorsFile,
                configFile,
              ]).then(async (res: any) => {
                if (res && res.files) {
                  await commit(r.device.id, false);
                }
              });
              return r.device.id;
            }
          }
        );
      }
    } catch (e) {
      setProcessError(String(e));
      console.log(e);
      devicePort?.close();
    }
  };

  const flash = async (devicePort: any) => {
    console.log("before get partitions");
    const devId = await getPartitions();
    console.log("after get partitions");
    const tmp = [...steps];
    tmp[1].status = "success";
    tmp[2].status = "pending";
    setActiveStep((prev) => prev + 1);
    setSteps([...tmp]);
    try {
      console.log("DP", devicePort);
      if (devicePort) {
        await openPort(devicePort, 115200);

        const loader = new ESPLoader(devicePort, {
          log: (...args) => console.log(...args),
          debug: (...args) => console.log(...args),
          error: (...args) => console.log(...args),
        });
        console.log("loader initializer", loader);
        try {
          await loader.initialize();
        } catch (error) {
          console.log("loader initialize error", error);
        }
        console.log("loader runstub");
        const espStub = await loader.runStub();
        console.log("setbaudrate");
        await espStub.setBaudrate(921600);
        console.log("PARTITIONS", partitions);
        const ps = [
          {
            name: "bootloader",
            data: partitions.bootloader,
            offset: 0x1000,
          },
          {
            name: "partitions",
            data: partitions.partitions,
            offset: 0x9000,
          },
          {
            name: "otalog",
            data: partitions.otalog,
            offset: 0x910000,
          },
          {
            name: "zerynth",
            data: partitions.zerynth,
            offset: 0x10000,
          },
          {
            name: "firmware",
            data: partitions.firmware,
            offset: 0x210000,
          },
          {
            name: "fs",
            data: partitions.fs,
            offset: 0x920000,
          },
        ];

        for (const p of ps) {
          const i = ps.indexOf(p);
          await espStub.flashData(
            p.data,
            (bytesWritten, totalBytes) => {
              setFlashPercentage(
                i * (100 / ps.length) +
                  (100 / ps.length) * (bytesWritten / totalBytes)
              );
            },
            p.offset
          );
        }

        console.log("stub disconnect");
        await espStub.hardReset();
        await espStub.disconnect();
        await devicePort.close();
        console.log("device flashed successfully");
        return devId;
      } else {
        setProcessError("No device port");
        console.log("NO DEVICE PORT");
      }
    } catch (e) {
      if (devicePort) {
        devicePort?.close();
      }
      setProcessError("Failed to flash firmware: " + String(e));
      console.log("failed to flash firmware", e);
      return "";
    }
    return devId;
  };

  const handleFirmwarePreferences = (port: any) => {
    flash(port).then(async (res) => {
      if (res) {
        console.log("device flashed successfully");
        const tmp = [...steps];
        tmp[2].status = "success";
        tmp[3].status = "pending";
        setActiveStep((prev) => prev + 1);
        setSteps([...tmp]);
        await handleClaimingDevice(port, res);
      } else {
        setProcessError("Failed to flash firmware");
      }
    });
  };

  //STEP 3: claimingDevice
  const handleClaimingDevice = useCallback(
    async (devicePort: SerialPort, devId: string) => {
      console.log("Starting claim procedure. Device Port: ", devicePort);
      await openPort(devicePort, 115200);
      console.log("port opened");
      // Read answer
      const textDecoder = new TextDecoderStream();
      const readableStreamClosed = devicePort.readable.pipeTo(
        textDecoder.writable
      );
      const reader = textDecoder.readable.getReader();
      console.log("reader created");
      let line = "";
      // Ask bundle to device
      const enc = new TextEncoder();
      // eslint-disable-next-line no-constant-condition
      while (true) {
        console.log("reading");
        const { value, done } = await reader.read();
        if (done) {
          reader.releaseLock();
          break;
        }
        const nonce = Math.floor(new Date().getTime() / 1000);
        line += value;
        if (value && value.includes("\n")) {
          console.log(line);
          if (line.includes("pong")) {
            if (devicePort) {
              const writer = devicePort.writable.getWriter();
              const data = enc.encode(`bundle ${nonce}\n`);
              await writer.write(data);
              writer.releaseLock();
            }
          } else if (line.includes(":")) {
            const bundle = line.replaceAll("\n", "").replaceAll("#", "");
            const parts = bundle.split(":");
            try {
              const b1 = window.atob(parts[0]);
              const b1o = JSON.parse(b1);
              // If the first part of the decoded bundle contains the same nonce => this is the bundle
              // Workaround: sometimes the nonce received from the firmware is x-1
              if (b1o.Nonce === `${nonce}` || b1o.Nonce === `${nonce - 1}`) {
                try {
                  console.log("bundle received ", devId);
                  const res = (await getIdentityFromDCN(b1o.DCN)) as any;
                  if (res && res.identity) {
                    if (res.identity.device_id !== devId) {
                      // Device already claimed by another device
                      setProcessError(
                        `Your physical device is already claimed by the device ${res.identity.device_id}. You should unclaim it before going further.`
                      );
                      console.log("device already claimed (another device id)");
                    } else {
                      const tmp = [...steps];
                      tmp[2].status = "success";
                      tmp[3].status = "pending";
                      setActiveStep((prev) => prev + 1);
                      setSteps([...tmp]);
                      console.log("device already claimed (this device id)");
                    }
                    const loader = new ESPLoader(devicePort, {
                      log: (...args) => console.log(...args),
                      debug: (...args) => console.log(...args),
                      error: (...args) => console.log(...args),
                    });
                    const espStub = await loader.runStub();
                    console.log("setbaudrate");
                    await espStub.setBaudrate(460800);
                    console.log("hardreset after claim");
                    await espStub.hardReset();
                    await espStub.disconnect();
                    await reader.cancel();
                    await readableStreamClosed.catch(() => {
                      /* Ignore the error */
                    });

                    const writer = devicePort.writable.getWriter();
                    const data = enc.encode(`exit\n`);
                    await writer.write(data);
                    writer.releaseLock();
                    break;
                  } else {
                    const res = (await claimDevice(
                      devId,
                      b1o.DCN,
                      bundle
                    )) as any;
                    if (res && res.identity) {
                      const tmp = [...steps];
                      tmp[2].status = "success";
                      tmp[3].status = "pending";
                      setActiveStep((prev) => prev + 1);
                      setSteps([...tmp]);
                      console.log("device claimed successfully");
                      if (network.IFCS[0].ifc_name === "gsm") {
                        const r: any = await ActiveSim(devId);
                        const writer = devicePort.writable.getWriter();
                        const data = enc.encode(`exit\n`);
                        await writer.write(data);
                        writer.releaseLock();
                        if (r && r.sim) {
                          // sim claimata correttamente
                          console.log("Sim claimed successfully");
                          createGrafanaDashboard(devId);
                        } else {
                          // errore durante il claim della sim (molto probabilmente non è stata sentita al momento della creazione del dcn)
                          // esstrarre la sim, inserirla di nuovo e riprovare tutta la procedura
                          setProcessError(
                            r && r.message
                              ? "Failed to claim sim: " + r.err.message
                              : "Failed to claim sim"
                          );
                        }
                      } else {
                        await reader.cancel();
                        await readableStreamClosed.catch(() => {
                          /* Ignore the error */
                        });
                        const writer = devicePort.writable.getWriter();
                        const data = enc.encode(`exit\n`);
                        await writer.write(data);
                        writer.releaseLock();
                        createGrafanaDashboard(devId);
                        break;
                      }
                    } else {
                      if (
                        res.message &&
                        (res.message.includes("could be counterfeited") ||
                          res.message.includes("is unknown"))
                      ) {
                        const tmp = [...steps];
                        tmp[2].status = "error";
                        setActiveStep((prev) => prev + 1);
                        setSteps([...tmp]);
                        setProcessError("Error: " + res.message);
                        await reader.cancel();
                        await readableStreamClosed.catch(() => {
                          /* Ignore the error */
                        });
                        const loader = new ESPLoader(devicePort, {
                          log: (...args) => console.log(...args),
                          debug: (...args) => console.log(...args),
                          error: (...args) => console.log(...args),
                        });
                        const espStub = await loader.runStub();
                        console.log("setbaudrate");
                        await espStub.setBaudrate(921600);
                        console.log("hardreset after claim");
                        await espStub.hardReset();
                        await espStub.disconnect();
                        break;
                      } else {
                        // TODO: handle other error responses
                      }
                    }
                  }
                } catch (e) {
                  console.log("catch", e);
                  break;
                } finally {
                  await reader.cancel();
                  await readableStreamClosed.catch(() => {
                    /* Ignore the error */
                  });
                  if (devicePort) {
                    devicePort?.close();
                  }
                }
              }
            } catch {
              console.log("failed to parse bundle");
            }
          } else if (line.includes("Inactivity timeout")) {
            console.log("timeout");
          } else if (line.includes("#")) {
            setTimeout(async () => {
              if (devicePort) {
                const writer = devicePort.writable.getWriter();
                const data = enc.encode(`ping\n`);
                await writer.write(data);
                writer.releaseLock();
              }
            }, 2000);
          }
          line = "";
        }
      }
    },
    [deviceId, steps]
  );

  //STEP 4: creatingDashboard
  const createGrafanaDashboard = async (devId: string) => {
    const tmp = [...steps];
    tmp[3].status = "success";
    tmp[4].status = "pending";
    setActiveStep((prev) => prev + 1);
    setSteps([...tmp]);
    const rawData: any = await getBasicDashboard();
    if (rawData && rawData.dashboard) {
      getFolders().then((folders: any) => {
        if (folders && folders.length > 0) {
          const tmp = folders.filter(
            (f: any) => f.title === userId.split("%%")[1].trim()
          );
          if (tmp.length > 0) {
            const folderId = tmp[0].id;
            createDashboard(
              rawData.dashboard,
              devId,
              deviceName,
              variables,
              folderId
            ).then((res: any) => {
              if (res && res.id) {
                setPermission(res.id, userId.split("%%")[0].trim()).then(
                  (res: any) => {
                    if (
                      res &&
                      res.message &&
                      res.message === "Dashboard permissions updated"
                    ) {
                      const tmp2 = [...steps];
                      tmp2[4].status = "success";
                      setSteps([...tmp2]);
                      setWritingFw(false);
                      navigate("/end");
                    } else {
                      setProcessError("Failed to set dashboard permission");
                    }
                  }
                );
              } else {
                setProcessError("Failed to create new dashboard");
              }
            });
          } else {
            createFolder(userId.split("%%")[1].trim()).then((f: any) => {
              if (f && f.id) {
                const folderId = f.id;
                createDashboard(
                  rawData.dashboard,
                  devId,
                  deviceName,
                  variables,
                  folderId
                ).then((res: any) => {
                  if (res && res.id) {
                    setPermission(res.id, userId.split("%%")[0].trim()).then(
                      (res: any) => {
                        if (
                          res &&
                          res.message &&
                          res.message === "Dashboard permissions updated"
                        ) {
                          const tmp2 = [...steps];
                          tmp2[4].status = "success";
                          setSteps([...tmp2]);
                          setWritingFw(false);
                          navigate("/end");
                        } else {
                          setProcessError("Failed to set dashboard permission");
                        }
                      }
                    );
                  } else {
                    setProcessError("Failed to create new dashboard");
                  }
                });
              } else {
                setProcessError("Failed to create new client folder");
              }
            });
          }
        } else {
          setProcessError("Failed to create new client folder");
        }
      });
    } else {
      setProcessError("Failed to create new dashboard");
    }
  };

  useEffect(() => {
    handleDeviceSelect();
  }, []);

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <CustomTitle
          goBack={writingFw ? undefined : () => navigate("/params")}
          title={`${t("configurationInProgress")}`}
        />
      </Grid>
      <Grid item xs={7}>
        <CustomCard
          content={
            usbDisconnected ? (
              <Grid
                container
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <CustomText label={t("disconnectedUsb")} type="h6" />
                </Grid>
                <Grid item>
                  <UsbOff style={{ fontSize: "128px" }} />
                </Grid>
                <Grid item xs={7}>
                  <CustomButton
                    fullWidth
                    type="contained"
                    label={t("retry")}
                    onClick={() => window.location.reload()}
                  />
                </Grid>
              </Grid>
            ) : processError ? (
              <Grid
                container
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <CustomText label={processError} type="h6" />
                </Grid>
                <Grid item>
                  <Dangerous style={{ fontSize: "128px", color: "red" }} />
                </Grid>
                <Grid item xs={7}>
                  <CustomButton
                    fullWidth
                    type="contained"
                    label={t("retry")}
                    onClick={() => window.location.reload()}
                  />
                </Grid>
              </Grid>
            ) : (
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map(
                  (step: { label: string; status: string }, index: number) => (
                    <Step key={step.label}>
                      <StepLabel>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <CustomText label={step.label} type="h6" />
                          </Grid>
                          {step.status === "pending" && (
                            <Grid item>
                              <CircularProgress size={25} />
                            </Grid>
                          )}
                          <Grid item xs />
                          {step.status === "pending" && index === 0 && (
                            <Grid item>
                              <CustomButton
                                type="contained"
                                label={t("connect")}
                                onClick={() => handleDeviceSelect()}
                              />
                            </Grid>
                          )}
                          {step.status === "pending" && index === 2 && (
                            <Grid item>{flashPercentage.toFixed(2)}%</Grid>
                          )}
                          {step.status === "pending" && index === 5 && (
                            <Grid item>
                              <CustomButton
                                type="contained"
                                label={t("skip")}
                                onClick={() => navigate("/end")}
                              />
                            </Grid>
                          )}
                        </Grid>
                        {step.status === "pending" &&
                          index === 3 &&
                          warning && (
                            <Grid item>
                              <Alert severity="warning" variant="filled">
                                {t("longDeviceConnection")}
                              </Alert>
                            </Grid>
                          )}
                      </StepLabel>
                    </Step>
                  )
                )}
              </Stepper>
            )
          }
        />
      </Grid>
    </Grid>
  );
};

export default Connecting;
