import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import { useNavigate } from "react-router-dom";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import CustomButton from "../../custom/CustomButton";
import useStore from "../../State";
import { enqueueSnackbar } from "notistack";

const NetworkGsm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const network = useStore((state: any) => state.network);
  const setNetwork = useStore((state: any) => state.setNetwork);

  const [configuration, setConfiguration] = useState<any>(
    network && network.IFCS.length === 1 && network.IFCS[0].ifc_name === "gsm"
      ? network
      : {
          IFCS: [
            {
              ifc_name: "gsm",
              ifc_params: {
               
              },
            },
          ],
        }
  );

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <CustomTitle
          goBack={() => navigate("/network")}
          title={`${t("gsmSettings")}`}
          subtitle={`${t("gsmDescription")}`}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <CustomCard
          content={
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Grid item>
                  <CustomInput
                    label={t("apn")}
                    value={configuration.IFCS[0].ifc_params.apn}
                    handleChange={(e) =>
                      setConfiguration({
                        ...configuration,
                        IFCS: [
                          {
                            ...configuration.IFCS[0],
                            ifc_params: {
                              ...configuration.IFCS[0].ifc_params,
                              apn: e.target.value,
                            },
                          },
                        ],
                      })
                    }
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} md>
                  <CustomButton
                    confirm
                    fullWidth
                    label={t("cancel")}
                    onClick={() =>
                      setConfiguration({
                        IFCS: [
                          {
                            ifc_name: "gsm",
                            ifc_params: {
                              apn: "",
                            },
                          },
                        ],
                      })
                    }
                    type="outlined"
                  />
                </Grid>
                <Grid item xs={12} md>
                  <CustomButton
                    fullWidth
                    label={t("ok")}
                    onClick={() => {
                      if (configuration.IFCS[0].ifc_params.apn) {
                        setNetwork({ ...configuration });
                        navigate("/plc");
                      } else {
                        enqueueSnackbar(t("invalidFields"), {
                          variant: "error",
                        });
                      }
                    }}
                    type="contained"
                  />
                </Grid>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
};

export default NetworkGsm;
