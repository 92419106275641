import { Grid } from "@mui/material";
import CustomTitle from "../../custom/CustomTitle";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { useState } from "react";
import CustomButton from "../../custom/CustomButton";
import { enqueueSnackbar } from "notistack";
import useStore from "../../State";
import CustomSelect from "../../custom/CustomSelect";

const NewVariable: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const variables = useStore((state: any) => state.variables);
  const setVariables = useStore((state: any) => state.setVariables);

  const [values, setValues] = useState({
    name: "",
    address: "",
    length: "1",
    type: "",
    unit: "",
    rw: "R",
    threshold: "",
    logic: "",
  });

  const checkFields = () =>
    values.name && values.address && values.type && values.rw;

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <CustomTitle
          goBack={() => navigate("/params")}
          title={`${t("newDinamicVariable")}`}
        />
      </Grid>
      <Grid item xs={7}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("nameVariable")}
                  value={values.name}
                  handleChange={(e) =>
                    setValues({ ...values, name: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("address")}
                  value={values.address}
                  handleChange={(e) =>
                    setValues({ ...values, address: e.target.value })
                  }
                  validator={{
                    error: Number(values.address) < 0,
                    helperText: `${t("positiveNumber")}`,
                  }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={
                    values.type === "boolean"
                      ? t("BitIndex")
                      : t("length")
                  }
                  value={values.length}
                  handleChange={(e) =>
                    setValues({ ...values, length: e.target.value })
                  }
                  type="number"
                  validator={{
                    error:
                      (values.type === "boolean" &&
                        (Number(values.length) < 0 ||
                          Number(values.length) > 31)) ||
                      (values.type !== "string" &&
                        values.type !== "boolean" &&
                        Number(values.length) < 0),
                    helperText:
                      values.type === "boolean"
                        ? `${t("bitPositionRange")}`
                        : `${t("positiveNumber")}`,
                  }}
                  disabled={values.type !== "string" && values.type !== "boolean"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={t("typeData")}
                  value={values.type}
                  handleChange={(e) =>
                    setValues({ ...values, type: e.target.value })
                  }
                  options={[
                    { label: "INT16", value: "INT16" },
                    { label: "UINT16", value: "UINT16" },
                    { label: "INT32", value: "INT32" },
                    { label: "UINT32", value: "UINT32" },
                    { label: "INT64", value: "INT64" },
                    { label: "UINT64", value: "UINT64" },
                    { label: "Float32", value: "Float32" },
                    { label: "Boolean", value: "boolean" }, // Added boolean option
                    { label: "String", value: "string" },
                    // Add more types as needed
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("unitMeasure")}
                  value={values.unit}
                  handleChange={(e) =>
                    setValues({ ...values, unit: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={t("rw")}
                  value={values.rw}
                  handleChange={(e) =>
                    setValues({ ...values, rw: e.target.value })
                  }
                  options={[
                    { label: "R", value: "R" },
                    { label: "W", value: "W" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("threshold")}
                  value={values.threshold}
                  handleChange={(e) =>
                    setValues({ ...values, threshold: e.target.value })
                  }
                  type="number"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={t("logic")}
                  value={values.logic}
                  handleChange={(e) =>
                    setValues({ ...values, logic: e.target.value })
                  }
                  options={[
                    { label: ">", value: ">" },
                    { label: ">=", value: ">=" },
                    { label: "<", value: "<" },
                    { label: "<=", value: "<=" },
                    { label: "==", value: "==" },
                    { label: "!=", value: "!=" },
                    { label: "d", value: "d" },
                  ]}
                />
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} md>
                  <CustomButton
                    confirm
                    fullWidth
                    label={t("cancel")}
                    onClick={() =>
                      setValues({
                        name: "",
                        address: "",
                        length: "",
                        type: "",
                        unit: "",
                        rw: "",
                        threshold: "",
                        logic: "",
                      })
                    }
                    type="outlined"
                  />
                </Grid>
                <Grid item xs={12} md>
                  <CustomButton
                    fullWidth
                    label={t("ok")}
                    onClick={() => {
                      if (checkFields()) {
                        setVariables([
                          ...variables,
                          {
                            ...values,
                            name: `custom_${values.name}`,
                            unit: values.unit ? values.unit : "Null",
                            threshold: values.threshold
                              ? values.threshold
                              : "Null",
                            logic: values.logic ? values.logic : "Null",
                          },
                        ]);
                        navigate("/params");
                      } else {
                        enqueueSnackbar(t("invalidFields"), {
                          variant: "error",
                        });
                      }
                    }}
                    type="contained"
                  />
                </Grid>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
};

export default NewVariable;
