import {
  adjustGrafanaDashboard,
  apiKey,
  dashboardUid,
  firmwareId,
  isDev,
  workspaceId,
} from "../pages/costant";
import { GET, GrafanaFetch, POST, PUT, ZDMFetch, binaryFetch } from "./http";

export const basicGrafanaUrl = "https://mapro.platform.zerynth.com/grafana/api";
export const basicZDMUrl = isDev
  ? "http://localhost/proxy"
  : "https://mapro.platform.zerynth.com/proxy";

export function findUser(loginOrEmail: string) {
  return GrafanaFetch(
    GET,
    `${basicGrafanaUrl}/users/lookup?loginOrEmail=${encodeURIComponent(
      loginOrEmail
    )}`
  );
}

export function findPermissions() {
  return GrafanaFetch(GET, `${basicGrafanaUrl}/org/users`);
}

export function getFolders() {
  if (isDev) {
    return new Promise((resolve) =>
      resolve([{ id: 22, uid: "WvEQ0JvIz", title: "test" }])
    );
  }
  return GrafanaFetch(GET, `${basicGrafanaUrl}/folders`);
}

export function createFolder(title: string) {
  if (isDev) {
    return new Promise((resolve) =>
      resolve({
        id: 22,
        uid: "WvEQ0JvIz",
        title: "test",
        url: "/grafana/dashboards/f/WvEQ0JvIz/test",
        hasAcl: false,
        canSave: true,
        canEdit: true,
        canAdmin: true,
        createdBy: "Anonymous",
        created: "2023-12-12T10:18:09Z",
        updatedBy: "Anonymous",
        updated: "2023-12-12T10:18:09Z",
        version: 1,
      })
    );
  }
  return GrafanaFetch(
    POST,
    `${basicGrafanaUrl}/folders`,
    JSON.stringify({ title: title })
  );
}

export function getBasicDashboard() {
  if (isDev) {
    return new Promise((resolve) =>
      resolve({
        meta: {
          type: "db",
          canSave: true,
          canEdit: true,
          canAdmin: true,
          canStar: true,
          slug: "esempio",
          url: "/grafana/d/bOSzrxd4kohj32/esempio",
          expires: "0001-01-01T00:00:00Z",
          created: "2023-11-06T18:13:00Z",
          updated: "2023-11-14T10:54:13Z",
          updatedBy: "admin",
          createdBy: "admin",
          version: 27,
          hasAcl: false,
          isFolder: false,
          folderId: 0,
          folderUid: "",
          folderTitle: "General",
          folderUrl: "",
          provisioned: false,
          provisionedExternalId: "",
        },
        dashboard: {
          annotations: {
            list: [
              {
                builtIn: 1,
                datasource: "-- Grafana --",
                enable: true,
                hide: true,
                iconColor: "rgba(0, 211, 255, 1)",
                name: "Annotations & Alerts",
                target: {
                  limit: 100,
                  matchAny: false,
                  tags: [],
                  type: "dashboard",
                },
                type: "dashboard",
              },
            ],
          },
          editable: true,
          fiscalYearStartMonth: 0,
          gnetId: null,
          graphTooltip: 1,
          id: 1,
          iteration: 1699959223101,
          links: [],
          liveNow: false,
          panels: [
            {
              datasource: null,
              gridPos: {
                h: 3,
                w: 24,
                x: 0,
                y: 0,
              },
              id: 25,
              options: {
                content:
                  '<div style="display:flex; justify-content:space-between; text-align: right;align-items:flex-start">\n<a> </a>\n\n<a style="margin-top:10px;"\nhref=\'$home_url\'>\n<img src="http://resources.demos.zerynth.com/images/zerynth_icon.png">\n</a>\n\n<p style="margin:0px;margin-top:5px;  font-size:3vw; width:60%;font-family: \'Open Sans\', sans-serif;font-weight: 700;text-align:center; width:100%">\n  $asset_name\n  </p>\n\n<img style="height:85px; margin-top:0px" \nsrc="http://resources.demos.zerynth.com/images/powered_by_zerynth.png">\n\n</div>',
                mode: "markdown",
              },
              pluginVersion: "8.2.2",
              targets: [
                {
                  format: "time_series",
                  group: [],
                  metricColumn: "none",
                  rawQuery: false,
                  rawSql:
                    "SELECT\n  $__time(time_column),\n  value1\nFROM\n  metric_table\nWHERE\n  $__timeFilter(time_column)\n",
                  refId: "A",
                  select: [
                    [
                      {
                        params: ["value"],
                        type: "column",
                      },
                    ],
                  ],
                  timeColumn: "time",
                  where: [
                    {
                      name: "$__timeFilter",
                      params: [],
                      type: "macro",
                    },
                  ],
                },
              ],
              type: "text",
            },
            {
              datasource: null,
              description: "",
              fieldConfig: {
                defaults: {
                  color: {
                    mode: "thresholds",
                  },
                  mappings: [],
                  noValue: "-",
                  thresholds: {
                    mode: "absolute",
                    steps: [
                      {
                        color: "text",
                        value: null,
                      },
                    ],
                  },
                },
                overrides: [
                  {
                    matcher: {
                      id: "byName",
                      options: "Stato Macchina",
                    },
                    properties: [
                      {
                        id: "mappings",
                        value: [
                          {
                            options: {
                              "-1": {
                                color: "#808080",
                                index: 3,
                                text: "Offline",
                              },
                              "0": {
                                color: "yellow",
                                index: 0,
                                text: "Offline",
                              },
                              "1": {
                                color: "green",
                                index: 1,
                                text: "Online",
                              },
                              "2": {
                                color: "red",
                                index: 2,
                                text: "Alarm",
                              },
                            },
                            type: "value",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    matcher: {
                      id: "byName",
                      options: "Tempo medio ciclo",
                    },
                    properties: [
                      {
                        id: "unit",
                        value: "dthms",
                      },
                    ],
                  },
                  {
                    matcher: {
                      id: "byName",
                      options: "Ore di Marcia",
                    },
                    properties: [
                      {
                        id: "unit",
                        value: "dthms",
                      },
                    ],
                  },
                ],
              },
              gridPos: {
                h: 4,
                w: 12,
                x: 0,
                y: 3,
              },
              id: 6,
              options: {
                colorMode: "value",
                graphMode: "none",
                justifyMode: "auto",
                orientation: "vertical",
                reduceOptions: {
                  calcs: ["lastNotNull"],
                  fields: "/.*/",
                  values: false,
                },
                text: {},
                textMode: "value_and_name",
              },
              pluginVersion: "8.2.2",
              targets: [
                {
                  format: "table",
                  group: [],
                  hide: false,
                  metricColumn: "none",
                  rawQuery: true,
                  rawSql:
                    "with\nstatus as (\n    select \ncase when type = 'connection' then 1 else 0 end as status \nfrom events \nwhere device_id = '$asset_id' and type in ('connection','disconnection') --and $__timeFilter(ts)\n    order by ts desc limit 1\n),\n\nval as (\n    select sum((payload#>'{vars, Ore di marcia,0}')::float) as ore_marcia,\n    avg((payload#>'{vars, Tempo Ciclo, 0}')::float) as t_medio\n    from data\n    where $__timeFilter(ts_device)\n    and device_id = '$asset_id'\n    and tag = 'd1'  \n)\n\nselect \n  coalesce((select status from status), -1) as \"Stato Macchina\"\n  ,(select ore_marcia from val) as \"Ore di Marcia\"\n  -- ,(select t_medio from val) as  \"Tempo medio ciclo\"\n\n",
                  refId: "B",
                  select: [
                    [
                      {
                        params: ["value"],
                        type: "column",
                      },
                    ],
                  ],
                  timeColumn: "time",
                  where: [
                    {
                      name: "$__timeFilter",
                      params: [],
                      type: "macro",
                    },
                  ],
                },
                {
                  format: "table",
                  group: [],
                  hide: false,
                  metricColumn: "none",
                  rawQuery: true,
                  rawSql:
                    "WITH\n  vista_fine AS (\n    SELECT\n      ts_device AS t_fine\n    FROM data\n    WHERE\n      device_id = '$asset_id'\n      AND tag in ('d1','s')\n      AND ( (payload#>'{vars, Elettrovalvola, 0}')::float > 0)\n    ORDER BY ts_device DESC LIMIT 1\n  ),\n  vista_inizio AS (\n    SELECT\n      ts_device AS t_inizio\n    FROM data\n    WHERE\n      device_id = '$asset_id'\n      AND tag IN ('d1','s')\n      AND ( (payload#>'{vars, Elettrovalvola, 0}')::float <= 0)\n      AND ts_device < (SELECT t_fine FROM vista_fine LIMIT 1)\n    ORDER BY ts_device DESC LIMIT 1\n  )\n  \nSELECT \nmax(ts_device)-min(ts_device) as \"Tempo Ciclo\"\nFROM data\nWHERE \n  device_id = '$asset_id'\n  AND tag IN ('d1','s')\n  AND ts_device >= (SELECT t_inizio FROM vista_inizio LIMIT 1)\n  AND ts_device <= (SELECT t_fine FROM vista_fine LIMIT 1);\n",
                  refId: "A",
                  select: [
                    [
                      {
                        params: ["value"],
                        type: "column",
                      },
                    ],
                  ],
                  timeColumn: "time",
                  where: [
                    {
                      name: "$__timeFilter",
                      params: [],
                      type: "macro",
                    },
                  ],
                },
              ],
              type: "stat",
            },
            {
              datasource: null,
              description: "",
              fieldConfig: {
                defaults: {
                  color: {
                    mode: "thresholds",
                  },
                  mappings: [],
                  noValue: "-",
                  thresholds: {
                    mode: "absolute",
                    steps: [
                      {
                        color: "text",
                        value: null,
                      },
                    ],
                  },
                },
                overrides: [
                  {
                    matcher: {
                      id: "byName",
                      options: "Stato Macchina",
                    },
                    properties: [
                      {
                        id: "mappings",
                        value: [
                          {
                            options: {
                              "-1": {
                                color: "#808080",
                                index: 3,
                                text: "Offline",
                              },
                              "0": {
                                color: "yellow",
                                index: 0,
                                text: "Offline",
                              },
                              "1": {
                                color: "green",
                                index: 1,
                                text: "Online",
                              },
                              "2": {
                                color: "red",
                                index: 2,
                                text: "Alarm",
                              },
                            },
                            type: "value",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    matcher: {
                      id: "byName",
                      options: "Tempo medio ciclo",
                    },
                    properties: [
                      {
                        id: "unit",
                        value: "dthms",
                      },
                    ],
                  },
                  {
                    matcher: {
                      id: "byName",
                      options: "Ore di Marcia",
                    },
                    properties: [
                      {
                        id: "unit",
                        value: "dthms",
                      },
                    ],
                  },
                ],
              },
              gridPos: {
                h: 4,
                w: 12,
                x: 12,
                y: 3,
              },
              id: 55,
              options: {
                colorMode: "value",
                graphMode: "none",
                justifyMode: "auto",
                orientation: "vertical",
                reduceOptions: {
                  calcs: ["lastNotNull"],
                  fields: "/.*/",
                  values: false,
                },
                text: {},
                textMode: "value_and_name",
              },
              pluginVersion: "8.2.2",
              targets: [
                {
                  format: "table",
                  group: [],
                  hide: false,
                  metricColumn: "none",
                  rawQuery: true,
                  rawSql:
                    "\n\n\n  select \n payload#>'{vars, Livello olio, 0}' as \"Livello olio\",\n payload#>'{vars, Pressione di mandata,0}' as \"Pressione mandata\",\n  payload#>'{vars, Pressione aspirazione,0}' as \"Pressione aspirazione\",\n payload#>'{vars, Temperatura di aspirazione ,0 }' as \"Temperatura di aspirazione\"\n from data\n    where $__timeFilter(ts_device)\n    and device_id = '$asset_id'\n    and tag = 'd1'\n    order by ts_device desc limit 1",
                  refId: "B",
                  select: [
                    [
                      {
                        params: ["value"],
                        type: "column",
                      },
                    ],
                  ],
                  timeColumn: "time",
                  where: [
                    {
                      name: "$__timeFilter",
                      params: [],
                      type: "macro",
                    },
                  ],
                },
              ],
              title: "Ultimi valori letti",
              type: "stat",
            },
            {
              datasource: null,
              fieldConfig: {
                defaults: {
                  color: {
                    fixedColor: "red",
                    mode: "fixed",
                  },
                  custom: {
                    axisLabel: "",
                    axisPlacement: "auto",
                    barAlignment: 0,
                    drawStyle: "line",
                    fillOpacity: 0,
                    gradientMode: "none",
                    hideFrom: {
                      legend: false,
                      tooltip: false,
                      viz: false,
                    },
                    lineInterpolation: "linear",
                    lineWidth: 1,
                    pointSize: 5,
                    scaleDistribution: {
                      type: "linear",
                    },
                    showPoints: "auto",
                    spanNulls: false,
                    stacking: {
                      group: "A",
                      mode: "none",
                    },
                    thresholdsStyle: {
                      mode: "off",
                    },
                  },
                  decimals: 2,
                  mappings: [],
                  min: 0,
                  thresholds: {
                    mode: "absolute",
                    steps: [
                      {
                        color: "yellow",
                        value: null,
                      },
                    ],
                  },
                  unit: "pressurebar",
                },
                overrides: [],
              },
              gridPos: {
                h: 5,
                w: 12,
                x: 0,
                y: 7,
              },
              id: 52,
              options: {
                legend: {
                  calcs: [],
                  displayMode: "list",
                  placement: "bottom",
                },
                tooltip: {
                  mode: "single",
                },
              },
              pluginVersion: "8.2.2",
              targets: [
                {
                  format: "time_series",
                  group: [],
                  hide: false,
                  metricColumn: "none",
                  rawQuery: true,
                  rawSql:
                    "\n\n\n  select \n  ts_device as time,\n-- payload#>'{vars, Livello olio, 0}' as \"Livello olio\",\n (payload#>'{vars, Pressione di mandata,0}')::float as \"Pressione mandata\"\n  -- payload#>'{vars, Pressione aspirazione,0}' as \"Pressione aspirazione\",\n-- payload#>'{vars, Temperatura di aspirazione ,0 }' as \"Temperatura di aspirazione\"\n from data\n    where $__timeFilter(ts_device)\n    and device_id = '$asset_id'\n    and tag = 'd1'\n    order by ts_device asc ",
                  refId: "B",
                  select: [
                    [
                      {
                        params: ["value"],
                        type: "column",
                      },
                    ],
                  ],
                  timeColumn: "time",
                  where: [
                    {
                      name: "$__timeFilter",
                      params: [],
                      type: "macro",
                    },
                  ],
                },
              ],
              title: "Pressione mandata",
              type: "timeseries",
            },
            {
              datasource: null,
              fieldConfig: {
                defaults: {
                  color: {
                    fixedColor: "red",
                    mode: "fixed",
                  },
                  custom: {
                    axisLabel: "",
                    axisPlacement: "auto",
                    barAlignment: 0,
                    drawStyle: "line",
                    fillOpacity: 0,
                    gradientMode: "none",
                    hideFrom: {
                      legend: false,
                      tooltip: false,
                      viz: false,
                    },
                    lineInterpolation: "linear",
                    lineWidth: 1,
                    pointSize: 5,
                    scaleDistribution: {
                      type: "linear",
                    },
                    showPoints: "auto",
                    spanNulls: false,
                    stacking: {
                      group: "A",
                      mode: "none",
                    },
                    thresholdsStyle: {
                      mode: "off",
                    },
                  },
                  decimals: 2,
                  mappings: [],
                  min: 0,
                  thresholds: {
                    mode: "absolute",
                    steps: [
                      {
                        color: "yellow",
                        value: null,
                      },
                    ],
                  },
                  unit: "percent",
                },
                overrides: [],
              },
              gridPos: {
                h: 5,
                w: 12,
                x: 12,
                y: 7,
              },
              id: 59,
              options: {
                legend: {
                  calcs: [],
                  displayMode: "list",
                  placement: "bottom",
                },
                tooltip: {
                  mode: "single",
                },
              },
              pluginVersion: "8.2.2",
              targets: [
                {
                  format: "time_series",
                  group: [],
                  hide: false,
                  metricColumn: "none",
                  rawQuery: true,
                  rawSql:
                    "\n\n\n  select \n  ts_device as time,\n(payload#>'{vars, Livello olio, 0}')::float as \"Livello olio\"\n-- (payload#>'{vars, Pressione di mandata,0}')::float as \"Pressione mandata\"\n  -- payload#>'{vars, Pressione aspirazione,0}' as \"Pressione aspirazione\",\n-- payload#>'{vars, Temperatura di aspirazione ,0 }' as \"Temperatura di aspirazione\"\n from data\n    where $__timeFilter(ts_device)\n    and device_id = '$asset_id'\n    and tag = 'd1'\n    order by ts_device asc ",
                  refId: "B",
                  select: [
                    [
                      {
                        params: ["value"],
                        type: "column",
                      },
                    ],
                  ],
                  timeColumn: "time",
                  where: [
                    {
                      name: "$__timeFilter",
                      params: [],
                      type: "macro",
                    },
                  ],
                },
              ],
              title: "Livello Olio",
              type: "timeseries",
            },
            {
              datasource: null,
              fieldConfig: {
                defaults: {
                  color: {
                    fixedColor: "red",
                    mode: "fixed",
                  },
                  custom: {
                    axisLabel: "",
                    axisPlacement: "auto",
                    barAlignment: 0,
                    drawStyle: "line",
                    fillOpacity: 0,
                    gradientMode: "none",
                    hideFrom: {
                      legend: false,
                      tooltip: false,
                      viz: false,
                    },
                    lineInterpolation: "linear",
                    lineWidth: 1,
                    pointSize: 5,
                    scaleDistribution: {
                      type: "linear",
                    },
                    showPoints: "auto",
                    spanNulls: false,
                    stacking: {
                      group: "A",
                      mode: "none",
                    },
                    thresholdsStyle: {
                      mode: "off",
                    },
                  },
                  decimals: 2,
                  mappings: [],
                  min: 0,
                  thresholds: {
                    mode: "absolute",
                    steps: [
                      {
                        color: "yellow",
                        value: null,
                      },
                    ],
                  },
                  unit: "pressurebar",
                },
                overrides: [],
              },
              gridPos: {
                h: 5,
                w: 12,
                x: 0,
                y: 12,
              },
              id: 57,
              options: {
                legend: {
                  calcs: [],
                  displayMode: "list",
                  placement: "bottom",
                },
                tooltip: {
                  mode: "single",
                },
              },
              pluginVersion: "8.2.2",
              targets: [
                {
                  format: "time_series",
                  group: [],
                  hide: false,
                  metricColumn: "none",
                  rawQuery: true,
                  rawSql:
                    "\n\n\n  select \n  ts_device as time,\n-- payload#>'{vars, Livello olio, 0}' as \"Livello olio\",\n-- (payload#>'{vars, Pressione di mandata,0}')::float as \"Pressione mandata\"\n  (payload#>'{vars, Pressione aspirazione,0}')::float as \"Pressione aspirazione\"\n-- payload#>'{vars, Temperatura di aspirazione ,0 }' as \"Temperatura di aspirazione\"\n from data\n    where $__timeFilter(ts_device)\n    and device_id = '$asset_id'\n    and tag = 'd1'\n    order by ts_device asc ",
                  refId: "B",
                  select: [
                    [
                      {
                        params: ["value"],
                        type: "column",
                      },
                    ],
                  ],
                  timeColumn: "time",
                  where: [
                    {
                      name: "$__timeFilter",
                      params: [],
                      type: "macro",
                    },
                  ],
                },
              ],
              title: "Pressione aspirazione",
              type: "timeseries",
            },
            {
              datasource: null,
              fieldConfig: {
                defaults: {
                  color: {
                    fixedColor: "red",
                    mode: "fixed",
                  },
                  custom: {
                    axisLabel: "",
                    axisPlacement: "auto",
                    barAlignment: 0,
                    drawStyle: "line",
                    fillOpacity: 0,
                    gradientMode: "none",
                    hideFrom: {
                      legend: false,
                      tooltip: false,
                      viz: false,
                    },
                    lineInterpolation: "linear",
                    lineWidth: 1,
                    pointSize: 5,
                    scaleDistribution: {
                      type: "linear",
                    },
                    showPoints: "auto",
                    spanNulls: false,
                    stacking: {
                      group: "A",
                      mode: "none",
                    },
                    thresholdsStyle: {
                      mode: "off",
                    },
                  },
                  decimals: 2,
                  mappings: [],
                  min: 0,
                  thresholds: {
                    mode: "absolute",
                    steps: [
                      {
                        color: "yellow",
                        value: null,
                      },
                    ],
                  },
                  unit: "watt",
                },
                overrides: [],
              },
              gridPos: {
                h: 5,
                w: 12,
                x: 12,
                y: 12,
              },
              id: 56,
              options: {
                legend: {
                  calcs: [],
                  displayMode: "list",
                  placement: "bottom",
                },
                tooltip: {
                  mode: "single",
                },
              },
              pluginVersion: "8.2.2",
              targets: [
                {
                  format: "time_series",
                  group: [],
                  hide: false,
                  metricColumn: "none",
                  rawQuery: true,
                  rawSql:
                    "\n\n\n  select \n  ts_device as time,\n-- payload#>'{vars, Livello olio, 0}' as \"Livello olio\",\n (payload#>'{vars, Pressione di mandata,0}')::float as \"Pressione mandata\"\n  -- payload#>'{vars, Pressione aspirazione,0}' as \"Pressione aspirazione\",\n-- payload#>'{vars, Temperatura di aspirazione ,0 }' as \"Temperatura di aspirazione\"\n from data\n    where $__timeFilter(ts_device)\n    and device_id = '$asset_id'\n    and tag = 'd1'\n    order by ts_device asc ",
                  refId: "B",
                  select: [
                    [
                      {
                        params: ["value"],
                        type: "column",
                      },
                    ],
                  ],
                  timeColumn: "time",
                  where: [
                    {
                      name: "$__timeFilter",
                      params: [],
                      type: "macro",
                    },
                  ],
                },
              ],
              title: "Potenza Assorbita",
              type: "timeseries",
            },
            {
              collapsed: false,
              datasource: null,
              gridPos: {
                h: 1,
                w: 24,
                x: 0,
                y: 17,
              },
              id: 61,
              panels: [],
              title: "Sezione Elettrovalvole",
              type: "row",
            },
            {
              datasource: null,
              fieldConfig: {
                defaults: {
                  color: {
                    fixedColor: "yellow",
                    mode: "fixed",
                  },
                  custom: {
                    axisLabel: "",
                    axisPlacement: "auto",
                    barAlignment: 0,
                    drawStyle: "line",
                    fillOpacity: 23,
                    gradientMode: "none",
                    hideFrom: {
                      legend: false,
                      tooltip: false,
                      viz: false,
                    },
                    lineInterpolation: "stepBefore",
                    lineWidth: 3,
                    pointSize: 5,
                    scaleDistribution: {
                      type: "linear",
                    },
                    showPoints: "auto",
                    spanNulls: false,
                    stacking: {
                      group: "A",
                      mode: "none",
                    },
                    thresholdsStyle: {
                      mode: "off",
                    },
                  },
                  decimals: 0,
                  mappings: [
                    {
                      options: {
                        "0": {
                          color: "yellow",
                          index: 0,
                          text: "OFF",
                        },
                        "1": {
                          color: "green",
                          index: 1,
                          text: "ON",
                        },
                      },
                      type: "value",
                    },
                  ],
                  min: 0,
                  thresholds: {
                    mode: "absolute",
                    steps: [
                      {
                        color: "yellow",
                        value: null,
                      },
                      {
                        color: "#EAB839",
                        value: 0,
                      },
                    ],
                  },
                  unit: "none",
                },
                overrides: [],
              },
              gridPos: {
                h: 7,
                w: 24,
                x: 0,
                y: 18,
              },
              id: 58,
              options: {
                legend: {
                  calcs: [],
                  displayMode: "list",
                  placement: "bottom",
                },
                tooltip: {
                  mode: "single",
                },
              },
              pluginVersion: "8.2.2",
              targets: [
                {
                  format: "time_series",
                  group: [],
                  hide: false,
                  metricColumn: "none",
                  rawQuery: true,
                  rawSql:
                    "\n\n\n  select \n  ts_device as time,\n(payload#>'{vars, Elettrovalvola, 0}')::int as \" \"\n-- (payload#>'{vars, Pressione di mandata,0}')::float as \"Pressione mandata\"\n  -- payload#>'{vars, Pressione aspirazione,0}' as \"Pressione aspirazione\",\n-- payload#>'{vars, Temperatura di aspirazione ,0 }' as \"Temperatura di aspirazione\"\n from data\n    where $__timeFilter(ts_device)\n    and device_id = '$asset_id'\n    and tag in('s')\n    order by ts_device asc ",
                  refId: "B",
                  select: [
                    [
                      {
                        params: ["value"],
                        type: "column",
                      },
                    ],
                  ],
                  timeColumn: "time",
                  where: [
                    {
                      name: "$__timeFilter",
                      params: [],
                      type: "macro",
                    },
                  ],
                },
              ],
              title: "Elettrovalvola 1",
              type: "timeseries",
            },
          ],
          refresh: "",
          schemaVersion: 31,
          style: "dark",
          tags: [],
          templating: {
            list: [
              {
                description: null,
                error: null,
                hide: 2,
                label: null,
                name: "asset_id",
                query: "dev-8f5lnln3jvqr",
                skipUrlSync: false,
                type: "constant",
              },
              {
                description: null,
                error: null,
                hide: 2,
                label: null,
                name: "asset_name",
                query: "Esempio Mapro",
                skipUrlSync: false,
                type: "constant",
              },
            ],
          },
          time: {
            from: "now-5m",
            to: "now",
          },
          timepicker: {
            refresh_intervals: ["5s", "10s", "30s", "1m"],
          },
          timezone: "",
          title: "Esempio",
          uid: "bOSzrxd4kohj32",
          version: 27,
        },
      })
    );
  }
  return GrafanaFetch(GET, `${basicGrafanaUrl}/dashboards/uid/${dashboardUid}`);
}

export function createDashboard(
  rawData: any,
  deviceId: string,
  name: string,
  variables: any[],
  folderId?: string
) {
  const raw_data = JSON.parse(JSON.stringify(rawData));
  for (let i = 0; i < raw_data.templating.list.length; i++) {
    if (raw_data.templating.list[i].name === "asset_id") {
      raw_data.templating.list[i].query = deviceId;
    }
    if (raw_data.templating.list[i].name === "asset_name") {
      raw_data.templating.list[i].query = name;
    }
  }
  raw_data.title = name;
  if (isDev) {
    return new Promise((resolve) =>
      resolve({
        id: 1,
        uid: "bOSzrxd4kohj32",
        title: "Esempio",
        uri: "db/esempio",
        url: "/grafana/d/bOSzrxd4kohj32/esempio",
        slug: "",
        type: "dash-db",
        tags: [],
        isStarred: true,
        sortMeta: 0,
      })
    );
  }
  return GrafanaFetch(
    POST,
    `${basicGrafanaUrl}/dashboards/db`,
    JSON.stringify({
      dashboard: adjustGrafanaDashboard(raw_data, variables),
      folderId: folderId ? folderId : 0,
      overwrite: true,
    })
  );
}

export function login(username: string, password: string) {
  return GrafanaFetch(
    POST,
    `https://mapro.platform.zerynth.com/grafana/login`,
    JSON.stringify({ user: username, password: password })
  );
}

export function getUsers() {
  if (isDev) {
    return new Promise((resolve) =>
      resolve([
        {
          id: 3,
          name: "gianluca@test.com",
          login: "gianluca@test.com",
          email: "gianluca@test.com",
          avatarUrl: "/grafana/avatar/0b1b3137c291c98739376578c6945www",
          isAdmin: false,
          isDisabled: false,
          lastSeenAt: "2023-06-05T06:48:51Z",
          lastSeenAtAge: "3 hours",
          authLabels: [],
        },
        {
          id: 6,
          name: "marco@test.com",
          login: "marco@test.com",
          email: "marco@test.com",
          avatarUrl: "/grafana/avatar/43f1f169334c5da6f609e9664bd11qqq",
          isAdmin: false,
          isDisabled: false,
          lastSeenAt: "2023-05-31T12:56:44Z",
          lastSeenAtAge: "4 days",
          authLabels: [],
        },
        {
          id: 9,
          name: "matteo@test.com",
          login: "matteo@test.com",
          email: "matteo@test.com",
          avatarUrl: "/grafana/avatar/43f1f169334c5da6f609e9664bd11sss",
          isAdmin: false,
          isDisabled: false,
          lastSeenAt: "2023-05-31T12:56:44Z",
          lastSeenAtAge: "4 days",
          authLabels: [],
        },
      ])
    );
  }
  return GrafanaFetch(GET, `${basicGrafanaUrl}/users`);
}

export function setPermission(dashId: string, userId: string) {
  if (isDev) {
    return new Promise((resolve) =>
      resolve({
        status: "success",
        message: "Dashboard permissions updated",
      })
    );
  }
  return GrafanaFetch(
    POST,
    `${basicGrafanaUrl}/dashboards/id/${dashId}/permissions`,
    JSON.stringify({
      items: [
        {
          userId: Number(userId),
          permission: 1, //1=Viewer 2=Editor
        },
      ],
    })
  );
}

export function searchDashboards(user: string, password: string) {
  return GrafanaFetch(
    GET,
    `${basicGrafanaUrl}/search/?folderIds=0&query=&starred=false`,
    undefined,
    `Basic ${btoa(`${user}:${password}`)}`
  );
}

export function createUser(user: any) {
  return GrafanaFetch(
    POST,
    `${basicGrafanaUrl}/admin/users`,
    JSON.stringify({
      name:user,
      email:user,
      login:user,
      password:user,
    })

  );
}
//DEVICE CONNECTING

export const createNewDevice = (req: { name: string; fleet_id?: string }) => {
  return ZDMFetch(
    POST,
    `${basicZDMUrl}/workspaces/${workspaceId}/devices`,
    JSON.stringify(req)
  );
};


export const getIdentityFromDCN = (dcn: string) => {
  return ZDMFetch(GET, `${basicZDMUrl}/identities/${dcn}/devices`);
};

export const claimDevice = (deviceId: string, dcn: string, bundle: string) => {
  return ZDMFetch(
    POST,
    `${basicZDMUrl}/workspaces/${workspaceId}/devices/${deviceId}/identities`,
    JSON.stringify({ bundle: bundle })
  );
};

export const ActiveSim = (deviceId: string) => {
  return ZDMFetch(
    PUT,
    `${basicZDMUrl}/workspaces/${workspaceId}/devices/${deviceId}/sims`,
    JSON.stringify({ status: "active" })
  );
};

export const mklfs = (files: any) => {
  return binaryFetch(POST, `${basicZDMUrl}/mklfs`, JSON.stringify(files));
};

export const uploadFiles = (deviceId: string, files: any[]) => {
  const formdata = new FormData();
  files.map((f) => formdata.append(f.file.name, f.file));
  return fetch(
    `${basicZDMUrl}/workspaces/${workspaceId}/devices/${deviceId}/zfs/upload`,
    {
      method: POST,
      body: formdata,
      headers: {
        Accept: "application/json",
        "X-API-KEY": apiKey,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const commit = (deviceId: string, schedule: boolean) => {
  return ZDMFetch(
    POST,
    `${basicZDMUrl}/workspaces/${workspaceId}/devices/${deviceId}/zfs/commit?auto_schedule=${
      schedule ? "true" : "false"
    }`
  );
};

export const getFirmwareVersions = () => {
  return ZDMFetch(
    GET,
    `${basicZDMUrl}/workspaces/${workspaceId}/firmwares/${firmwareId}/versions`
  );
};

export const getDevice = (deviceId: string) => {
  return ZDMFetch(
    GET,
    `${basicZDMUrl}/workspaces/${workspaceId}/devices/${deviceId}`
  );
};
