import { Collapse, Grid, Switch } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { isValidIPv4 } from "../costant";
import CustomTitle from "../../custom/CustomTitle";
import { useNavigate } from "react-router-dom";
import CustomCard from "../../custom/CustomCard";
import CustomText from "../../custom/CustomText";
import CustomInput from "../../custom/CustomInput";
import CustomButton from "../../custom/CustomButton";
import useStore from "../../State";

const NetworkEth: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const network = useStore((state: any) => state.network);
  const setNetwork = useStore((state: any) => state.setNetwork);

  const [configuration, setConfiguration] = useState<any>(
    network && network.IFCS.length === 1 && network.IFCS[0].ifc_name === "eth"
      ? network
      : {
          IFCS: [
            {
              ifc_name: "eth",
              ifc_params: {
                dhcp: true,
              
              },
            },
          ],
        }
  );

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <CustomTitle
          goBack={() => navigate("/network")}
          title={`${t("ethernetSettings")}`}
          subtitle={`${t("ethernetDescription")}`}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <CustomCard
          content={
            <Grid container spacing={2} direction="column">
              <Grid item>
                <CustomText label={t("ipv4")} type="h6" />
              </Grid>
              <Grid item>
                <Switch
                  checked={!configuration.IFCS[0].ifc_params.dhcp}
                  onChange={() =>
                    setConfiguration({
                      ...configuration,
                      IFCS: [
                        {
                          ...configuration.IFCS[0],
                          ifc_params: {
                            ...configuration.IFCS[0].ifc_params,
                            dhcp: !configuration.IFCS[0].ifc_params.dhcp,
                          },
                        },
                      ],
                    })
                  }
                />
                {!configuration.IFCS[0].ifc_params.dhcp
                  ? " " + t("manual")
                  : " " + t("automaticDhcp")}
              </Grid>
              <Grid item>
                <Collapse
                  in={!configuration.IFCS[0].ifc_params.dhcp}
                  timeout="auto"
                  unmountOnExit
                >
                  <Grid container spacing={2} direction="column">
                    <Grid item>
                      <CustomInput
                        label={t("ipAddress")}
                        value={configuration.IFCS[0].ifc_params.ip}
                        handleChange={(e) =>
                          setConfiguration({
                            ...configuration,
                            IFCS: [
                              {
                                ...configuration.IFCS[0],
                                ifc_params: {
                                  ...configuration.IFCS[0].ifc_params,
                                  ip: e.target.value,
                                },
                              },
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!configuration.IFCS[0].ifc_params.ip &&
                            !isValidIPv4(configuration.IFCS[0].ifc_params.ip),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <CustomInput
                        label={t("subnetMask")}
                        value={configuration.IFCS[0].ifc_params.mask}
                        handleChange={(e) =>
                          setConfiguration({
                            ...configuration,
                            IFCS: [
                              {
                                ...configuration.IFCS[0],
                                ifc_params: {
                                  ...configuration.IFCS[0].ifc_params,
                                  mask: e.target.value,
                                },
                              },
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!configuration.IFCS[0].ifc_params.mask &&
                            !isValidIPv4(configuration.IFCS[0].ifc_params.mask),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <CustomInput
                        label={t("gateway")}
                        value={configuration.IFCS[0].ifc_params.gateway}
                        handleChange={(e) =>
                          setConfiguration({
                            ...configuration,
                            IFCS: [
                              {
                                ...configuration.IFCS[0],
                                ifc_params: {
                                  ...configuration.IFCS[0].ifc_params,
                                  gateway: e.target.value,
                                },
                              },
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!configuration.IFCS[0].ifc_params.gateway &&
                            !isValidIPv4(
                              configuration.IFCS[0].ifc_params.gateway
                            ),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <CustomInput
                        label={t("dns")}
                        value={configuration.IFCS[0].ifc_params.dns}
                        handleChange={(e) =>
                          setConfiguration({
                            ...configuration,
                            IFCS: [
                              {
                                ...configuration.IFCS[0],
                                ifc_params: {
                                  ...configuration.IFCS[0].ifc_params,
                                  dns: e.target.value,
                                },
                              },
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!configuration.IFCS[0].ifc_params.dns &&
                            !isValidIPv4(configuration.IFCS[0].ifc_params.dns),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} md>
                  <CustomButton
                    confirm
                    fullWidth
                    label={t("cancel")}
                    onClick={() =>
                      setConfiguration({
                        IFCS: [
                          {
                            ifc_name: "eth",
                            ifc_params: {
                              dhcp: true,
                              ip: "",
                              mask: "",
                              gateway: "",
                              dns: "",
                            },
                          },
                        ],
                      })
                    }
                    type="outlined"
                  />
                </Grid>
                <Grid item xs={12} md>
                  <CustomButton
                    fullWidth
                    label={t("ok")}
                    onClick={() => {
                      setNetwork({ ...configuration });
                      navigate("/plc");
                    }}
                    type="contained"
                  />
                </Grid>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
};

export default NetworkEth;
