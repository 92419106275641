import { Grid, Typography } from "@mui/material";
import React from "react";
import { Cable } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomCard from "../../custom/CustomCard";
import { useNavigate } from "react-router-dom";

const Home: React.FC = () => {
  document.body.style.backgroundColor = "white";

  const { t } = useTranslation();
  const navigate = useNavigate();

  const homeOptions = [
    {
      id: "first",
      icon: <Cable style={{ fontSize: "56px" }} />,
      title: t("firstConfiguration"),
    },
    /*
        {
            id: "notFirst",
            icon: <CloudSync style={{ fontSize: "32px" }} />,
            title: t("otherConfiguration"),
            description: t("otherConfigurationDescription"),
        },
    */
  ];

  return (
    <Grid container spacing={2} direction="column">
      <Grid item xs={12}>
        <CustomTitle
          title={`${t("configurationType")}`}
          subtitle={`${t("configurationTypeDescription")}`}
        />
      </Grid>
      <Grid
        item
        container
        spacing={2}
        style={{ paddingLeft: "64px", paddingRight: "64px" }}
      >
        {homeOptions.map((opt, index) => {
          return (
            <Grid key={index} item xs={12} md={4}>
              <CustomCard
                content={
                  <Grid
                    container
                    spacing={2}
                    direction="column"
                    alignItems="center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/name");
                    }}
                  >
                    <Grid item>{opt.icon}</Grid>
                    <Grid item>
                      <Typography variant="h5">{opt.title}</Typography>
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Home;
