import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import logo from "../../images/mapro.png";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useStore from "../../State";
import { useSnackbar } from "notistack";
import CustomCard from "../../custom/CustomCard";
import CustomText from "../../custom/CustomText";
import CustomInput from "../../custom/CustomInput";
import CustomIconButton from "../../custom/CustomIconButton";
import CustomButton from "../../custom/CustomButton";
import { lightColor } from "../../custom/utils";
import { isDev } from "../costant";
import { findPermissions, login } from "../../endpoints/api";

const Login: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const setLoggedIn = useStore((state: any) => state.setLoggedIn);
  const setUser = useStore((state: any) => state.setUser);

  document.body.style.backgroundColor = lightColor;
  const [heigth, setHeigth] = useState<number>(window.innerHeight);
  useEffect(() => {
    window.addEventListener("resize", () => setHeigth(window.innerHeight));
    return () =>
      window.removeEventListener("resize", () => setHeigth(window.innerHeight));
  }, []);

  useEffect(() => {
    setLoggedIn(false);
  }, []);

  const [name, setName] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  return (
    <Grid container height={heigth} justifyContent="center" alignItems="center">
      <Grid item xs={9}>
        <CustomCard
          content={
            <Grid container alignItems="center">
              <Grid item xs={4}>
                <img src={logo} alt="logo" style={{ width: "90%" }} />
              </Grid>
              <Grid item container xs={8} flexDirection="column" spacing={2}>
                <Grid item>
                  <CustomText label={t("welcomeBack")} type="h3" />
                </Grid>
                <Grid item>
                  <CustomInput
                    label={t("email")}
                    value={name}
                    handleChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <CustomInput
                    label={t("password")}
                    type={showPassword ? "text" : "password"}
                    value={password}
                    handleChange={(e) => setPassword(e.target.value)}
                    endAdornment={
                      <CustomIconButton
                        type="outlined"
                        icon={showPassword ? <VisibilityOff /> : <Visibility />}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    }
                  />
                </Grid>
                <Grid item>
                  <CustomButton
                    fullWidth
                    type="contained"
                    label={t("login")}
                    onClick={() => {
                      if (isDev) {
                        setLoggedIn(true);
                        setUser({
                          name: "test-zerynth",
                          password: "test-zerynth",
                          admin: true,
                        });
                        navigate("/home");
                      } else {
                        login(name, password).then((res) => {
                          if (res && res.message === "Logged in") {
                            findPermissions().then((result) => {
                              const tmp = ((result as any[]) || []).filter(
                                (r) => r.email === name
                              );
                              if (tmp.length > 0) {
                                setLoggedIn(true);
                                setUser({
                                  name: name,
                                  password: password,
                                  admin: tmp[0].role === "Admin" ? true : false,
                                });
                                navigate("/home");
                              } else {
                                //configurator disponibile solo per admin
                                enqueueSnackbar(t("loginError"), {
                                  variant: "error",
                                });
                              }
                            });
                          } else {
                            enqueueSnackbar(t("loginError"), {
                              variant: "error",
                            });
                          }
                        });
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
};

export default Login;
