import { Grid } from "@mui/material";
import CustomTitle from "../../custom/CustomTitle";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CheckCircle } from "@mui/icons-material";
import CustomText from "../../custom/CustomText";
import CustomButton from "../../custom/CustomButton";
import useStore from "../../State";
import { useEffect } from "react";

const End: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const setLoggedIn = useStore((state: any) => state.setLoggedIn);

  useEffect(() => {
    const interval = localStorage.getItem("interval_mapro");
    if (interval) {
      clearInterval(parseInt(interval));
    }
  }, []);

  return (
    <Grid container spacing={2} flexDirection="column" alignItems="center">
      <Grid item xs={12} width="100%">
        <CustomTitle
          goBack={() => navigate("/connecting")}
          title={`${t("endProcedure")}`}
        />
      </Grid>
      <Grid item xs={10}>
        <CheckCircle style={{ fontSize: "128px", color: "green" }} />
      </Grid>
      <Grid item xs={10}>
        <CustomText type="h4" label={`${t("finishConfiguration")}`} />
      </Grid>
      <Grid item xs={10}>
        <CustomText
          type="h6"
          label={`${t("finishConfigurationDescription")}`}
        />
      </Grid>
      <Grid item xs={7} container spacing={2}>
        <Grid item xs={12} md>
          <CustomButton
            fullWidth
            label={t("logout")}
            onClick={() => {
              setLoggedIn(false);
              navigate("/login");
            }}
            type="outlined"
          />
        </Grid>
        <Grid item xs={12} md>
          <CustomButton
            fullWidth
            label={t("end")}
            onClick={() => navigate("/home")}
            type="contained"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default End;
