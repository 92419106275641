import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import CustomTable from "../../custom/CustomTable";
import CustomText from "../../custom/CustomText";
import { useState } from "react";
import { Add, ArrowForwardIosSharp, Delete } from "@mui/icons-material";
import CustomButton from "../../custom/CustomButton";
import useStore from "../../State";
import CustomInput from "../../custom/CustomInput";
import { GridRenderCellParams } from "@mui/x-data-grid";
import CustomIconButton from "../../custom/CustomIconButton";

const Params: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const variables = useStore((state: any) => state.variables);
  const setVariables = useStore((state: any) => state.setVariables);

  const [expanded, setExpanded] = useState<string | false>("panel2");
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const staticColumns = [
    {
      headerName: `${t("nameVariable")}`,
      field: "name",
      flex: 2,
    },
    { headerName: `${t("address")}`, field: "address", flex: 1 },
    {
      headerName: `${t("length")}`,
      field: "length",
      flex: 1,
    },
    { headerName: `${t("typeData")}`, field: "type", flex: 1 },
    { headerName: `${t("unitMeasure")}`, field: "unit", flex: 1.2 },
    { headerName: `${t("rw")}`, field: "rw", flex: 1 },
    { headerName: `${t("threshold")}`, field: "threshold", flex: 1 },
    { headerName: `${t("logic")}`, field: "logic", flex: 1 },
  ];

  const variableColumns = [
    {
      headerName: `${t("nameVariable")}`,
      field: "name",
      flex: 2,
    },
    { headerName: `${t("address")}`, field: "address", flex: 1 },
    {
      headerName: `${t("length")}`,
      field: "length",
      flex: 1,
    },
    { headerName: `${t("typeData")}`, field: "type", flex: 1 },
    { headerName: `${t("unitMeasure")}`, field: "unit", flex: 1.2 },
    { headerName: `${t("rw")}`, field: "rw", flex: 1 },
    { headerName: `${t("threshold")}`, field: "threshold", flex: 1 },
    { headerName: `${t("logic")}`, field: "logic", flex: 1 },
    {
      headerName: `${t("delete")}`,
      field: "",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <CustomIconButton
          confirm
          type="outlined"
          icon={<Delete />}
          onClick={(e: any) => {
            setVariables(
              variables.filter((v: any) => v.name !== params.row.name)
            );
          }}
        />
      ),
    },
  ];

  const [open, setOpen] = useState<any>(null);
  const [staticVariables, setStaticVariables] = useState<any[]>([
    {
      name: "Pressione aspirazione",
      address: "1",
      length: "2",
      type: "Float32",
      unit: "Bar",
      rw: "R",
      threshold: "Null",
      logic: "Null",
    },
    {
      name: "Temperatura di aspirazione",
      address: "3",
      length: "2",
      type: "Float32",
      unit: "C°",
      rw: "R",
      threshold: "Null",
      logic: "Null",
    },
    {
      name: "Pressione di mandata",
      address: "5",
      length: "2",
      type: "Float32",
      unit: "Bar",
      rw: "R",
      threshold: "Null",
      logic: "Null",
    },
    {
      name: "Temperatura di mandata",
      address: "7",
      length: "2",
      type: "Float32",
      unit: "C°",
      rw: "R",
      threshold: "Null",
      logic: "Null",
    },
    {
      name: "Livello olio",
      address: "9",
      length: "2",
      type: "Float32",
      unit: "%",
      rw: "R",
      threshold: "Null",
      logic: "Null",
    },
    {
      name: "Potenza",
      address: "11",
      length: "1",
      type: "Float32",
      unit: "W",
      rw: "R",
      threshold: "Null",
      logic: "Null",
    },
    {
      name: "Ore di marcia",
      address: "12",
      length: "1",
      type: "UINT16",
      unit: "h (ore)",
      rw: "R",
      threshold: "Null",
      logic: "Null",
    },
    {
      name: "Sensore di prossimità",
      address: "13",
      length: "1",
      type: "UINT16",
      unit: "Null",
      rw: "R",
      threshold: "Null",
      logic: "Null",
    },
    {
      name: "Elettrovalvola",
      address: "14",
      length: "1",
      type: "UINT16",
      unit: "Null",
      rw: "R",
      threshold: "Null",
      logic: "Null",
    },
  ]);

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <CustomTitle
          goBack={() => navigate("/network")}
          title={`${t("variables")}`}
        />
      </Grid>
      <Grid item xs={10}>
        <Accordion
          variant="outlined"
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            style={{ flexDirection: "row-reverse" }}
            expandIcon={<ArrowForwardIosSharp />}
          >
            <CustomText label={`${t("staticVariables")}`} type="h6" />
          </AccordionSummary>
          <AccordionDetails>
            <CustomTable
              columns={staticColumns}
              rows={staticVariables}
              onRowClick={(row) => setOpen(row)}
            />
          </AccordionDetails>
        </Accordion>
        <Dialog open={open ? true : false} onClose={() => setOpen(null)}>
          <DialogTitle>
            {open && open.name ? open.name : `${t("changeAddress")}`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ marginTop: "8px" }}>
              <CustomInput
                type="number"
                label={`${t("address")}`}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (open && open.name) {
                    const newStaticVariables: any[] = staticVariables.map(
                      (variable) => {
                        if (variable.name === open.name) {
                          return { ...variable, address: e.target.value };
                        }
                        return variable;
                      }
                    );
                    setStaticVariables([...newStaticVariables]);
                  }
                }}
                value={
                  open && open.name
                    ? staticVariables.find(
                        (variable) => variable.name === open.name
                      )?.address
                    : ""
                }
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CustomButton
              label={t("no")}
              type="outlined"
              onClick={() => setOpen(null)}
            />
            <CustomButton
              label={t("yes")}
              type="contained"
              onClick={() => {
                setOpen(null);
              }}
            />
          </DialogActions>
        </Dialog>
      </Grid>
      <Grid item xs={10}>
        <Accordion
          variant="outlined"
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            style={{ flexDirection: "row-reverse" }}
            expandIcon={<ArrowForwardIosSharp />}
          >
            <Grid container spacing={2}>
              <Grid item>
                <CustomText label={`${t("dinamicVariables")}`} type="h6" />
              </Grid>
              <Grid item xs />
              <Grid item>
                {expanded === "panel2" && (
                  <CustomButton
                    type="contained"
                    endIcon={<Add />}
                    label={`${t("new")}`}
                    onClick={() => navigate("/params/new")}
                  />
                )}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <CustomTable
              columns={variableColumns}
              rows={variables.filter((v: any) =>
                String(v.name).startsWith("custom_")
              )}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item container xs={10} spacing={2}>
        <Grid item xs={12} md>
          <CustomButton
            confirm
            fullWidth
            label={t("cancel")}
            onClick={() => console.log("test")}
            type="outlined"
          />
        </Grid>
        <Grid item xs={12} md>
          <CustomButton
            fullWidth
            label={t("ok")}
            onClick={() => {
              if (
                variables &&
                variables.length > 0 &&
                variables[0].name !== staticVariables[0].name
              ) {
                setVariables([...staticVariables, ...variables]);
              } else {
                if (variables && variables.length === 0) {
                  setVariables([...staticVariables, ...variables]);
                }
              }
              navigate("/connecting");
            }}
            type="contained"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Params;
